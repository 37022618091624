import React from "react";
import {
  doesUserHasRole,
  PYPE_MANAGER_ROLES,
  PYPE_AGENT_ROLES,
} from "bot-user-session";

class AllowedPath extends React.Component {
  render() {
    const hasPype =
      doesUserHasRole(PYPE_MANAGER_ROLES) || doesUserHasRole(PYPE_AGENT_ROLES);

    if (hasPype) {
      window.location = "/pype-manager";
      return <></>;
    } else {
      window.location = "/console";
      return <></>;
    }
  }
}

export default AllowedPath;
