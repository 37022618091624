/* eslint-disable no-unused-vars */
/* eslint-disable no-async-promise-executor */
import Papa from "papaparse";
import isEmpty from "lodash/isEmpty";
import botManagerAPI from "../../../bot-manager-api";
import { addAdditionalData } from "../../Utils/node-statistics";
import { initBot } from "../../../Manager/Store/actions";
import activityTracker from "../../../activityTracker";
import { parseSolutionDataToCSVData } from "../../Utils/parse";
import { SOLUTION_DATA_SETTING, SYSTEM_NODES, TAB_LIST } from "../../enum";
import {
  SET_SOLUTION_DATA,
  RESET_SOLUTION,
  IMPORT_SOLUTION,
  ADD_NODE_TO_BOTTOM,
  ADD_SIBLING_NODE,
  ADD_NODE_BETWEEN_PARENT_CHILD,
  DELETE_BOTTOM_NODE,
  DELETE_MIDDLE_NODE,
  UPDATE_NODE,
  UPDATE_NODE_LIST,
  ADD_NEW_BRANCH,
  DELETE_BRANCH,
  UN_DO,
  RE_DO,
  CREATE_CHILD_NODE,
  SET_GLOBAL_VARIABLES,
  SET_BOT_UPLOADING,
  SET_SYSTEM_NODES,
  SWITCH_TAB,
  MOVE_NODE_BETWEEN,
  SET_SOLUTION_ERRORS,
  SET_SOLUTION_VERSION_INFO,
  SET_PERSISTENT_MENUS,
  SET_GRAPH_HAS_CHANGES,
  SET_SOLUTION_FLAGS,
} from "./actionTypes";
import { convertGraphToJSON } from "../../Utils/graph_to_flat_json";

// eslint-disable-next-line no-useless-escape
const UNESCAPE_CHAR_PATTERN = /("\s*|\n|,\s*)'(?=\s*[@\+\-=\|%])/gm;

const CSVHeaders = SOLUTION_DATA_SETTING.HEADERS;

export const setSolutionData = (solution) => ({
  type: SET_SOLUTION_DATA,
  data: solution,
});

export const resetSolution = () => ({
  type: RESET_SOLUTION,
});

export const importSolution = (data) => ({
  type: IMPORT_SOLUTION,
  data: {
    data,
  },
});

export const addNodeToBottom = (parent, node, callback) => ({
  type: ADD_NODE_TO_BOTTOM,
  data: {
    parent,
    node,
    callback,
  },
});

export const addSiblingNode = (nodeInfo, moveInfo, callback) => ({
  type: ADD_SIBLING_NODE,
  data: {
    nodeInfo,
    moveInfo,
    callback,
  },
});

export const addNodeBetweenParentAndChild = (nodeInfo, moveInfo, callback) => ({
  type: ADD_NODE_BETWEEN_PARENT_CHILD,
  data: {
    nodeInfo,
    moveInfo,
    callback,
  },
});

export const deleteBottomNode = (id) => ({
  type: DELETE_BOTTOM_NODE,
  data: {
    id,
  },
});

export const deleteMiddleNode = (parent, id) => ({
  type: DELETE_MIDDLE_NODE,
  data: { parent, id },
});

export const updateNode = (node) => ({
  type: UPDATE_NODE,
  data: {
    node,
  },
});

export const updateNodeList = (nodes) => ({
  type: UPDATE_NODE_LIST,
  data: {
    nodes,
  },
});

export const addNewBranch = (nodeInfo, branchInfo, callback) => ({
  type: ADD_NEW_BRANCH,
  data: {
    nodeInfo,
    branchInfo,
    callback,
  },
});

export const addNewBranchToSelectedTab = (from, to, nodeInfo, callback) => ({
  type: MOVE_NODE_BETWEEN,
  data: {
    from,
    to,
    nodeInfo,
    callback,
  },
});

export const deleteBranch = (node, callback) => ({
  type: DELETE_BRANCH,
  data: { node, callback },
});

export const unDo = () => ({
  type: UN_DO,
});

export const reDo = () => ({
  type: RE_DO,
});

export const createChildNode = (node, pos, callback) => ({
  type: CREATE_CHILD_NODE,
  data: { node, pos, callback },
});

export const setGlobalVariables = (data) => ({
  type: SET_GLOBAL_VARIABLES,
  data: { data },
});

export const setSolutionVersionInfo = (customerId, botId) => ({
  type: SET_SOLUTION_VERSION_INFO,
  data: { customerId, botId },
});

export const loadSolutionGraph = (solutionId, botId, callback) => {
  return async (dispatch, getState) => {
    try {
      const { customers } = getState().manager;

      if (customers[solutionId] && customers[solutionId].botsData[botId]) {
        const { latestVersion } = customers[solutionId].botsData[botId];

        if (latestVersion) {
          const response = await botManagerAPI.getSolutionGraph(
            latestVersion.id
          );

          if (response.status === 200) {
            if (callback) {
              const {
                data: { file_data: graphData },
              } = response.data || {};
              callback("JSON", graphData);
            }
          } else if (response.status === 404) {
            // Get CSV Data
            const serverFiles = Object.values(latestVersion.files);
            if (serverFiles.indexOf("templates/bot.csv") > -1) {
              const response = await botManagerAPI.downloadTemplateFile(
                latestVersion.id,
                "templates/bot.csv"
              );

              let result = await importCsvFromStream(response.file_data, false);
              result = addAdditionalData(result.data);
              callback("CSV", result);
            }
          }
        }
      }
    } catch (err) {
      if (callback) {
        callback("", null);
      }
    }
  };
};

export const uploadSolutionGraph = (
  solutionId,
  versionId,
  isChecking,
  callback
) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const dialogSolution =
        state.solution[TAB_LIST.Dialog].present?.solution ?? {};
      const systemSolution =
        state.solution[TAB_LIST.System].present?.solution ?? {};
      const flatJSONData = [
        ...convertGraphToJSON(dialogSolution),
        ...convertGraphToJSON(systemSolution).map((n) => ({
          ...n,
          metadata: {
            ...n.metadata,
            usecase: "system",
          },
        })),
      ];
      if (!isEmpty(state.solution.persistentMenus)) {
        const persistentMenu = {
          number: -411,
          type: "D",
          name: "Persistent Menu",
          nodeContent: {
            nluDisabled: true,
            messages: [],
            richAssetType: "persistent_menu",
            richAssetContent: {
              type: "static",
              options: state.solution.persistentMenus.map((m) => ({
                label: m.label,
                dest: m.link?.number,
                set_value: "",
              })),
            },
          },
        };
        flatJSONData.push(persistentMenu);
      }
      const { customers } = state.manager;
      const { latestVersion, hasDraft } =
        customers[solutionId].botsData[versionId];

      if (latestVersion) {
        let uploadToVersionId = null;
        if (hasDraft) {
          uploadToVersionId = latestVersion.id;
        } else {
          await botManagerAPI.createNewBotVersion(
            `${solutionId}.${versionId}`,
            latestVersion ? latestVersion.version : null
          );
          const res = await dispatch(initBot(solutionId, versionId));
          uploadToVersionId = res.botData.latestVersion.id;
        }

        const response = await botManagerAPI.updateSolutionGraph(
          uploadToVersionId,
          {
            templateData: flatJSONData,
          }
        );

        if (response.status === 200) {
          if (callback) {
            callback(false, { data: "Solution was uploaded successfully" });
          }

          activityTracker.logEvent(
            activityTracker.eventTypeNames.SAVE_SOLUTION_CHANGES,
            {
              numberOfWarnings: 1,
              success: true,
              solutionName: `${state.designer.present.customerId}:${state.designer.present.botId}`,
            }
          );

          if (isChecking) {
            // Call /validate
            const { status, data } = await botManagerAPI.validateVersion(
              uploadToVersionId
            );
            if (status === 200) {
              callback(false, data);
            } else {
              callback(true, data.messages || [], "validate");
            }
          }
        } else {
          callback(true, response.data.errors || [], "upload");
        }

        dispatch(setGraphHasChanges(false));
      }
    } catch (err) {
      console.log(err);
      if (callback) {
        callback(true, "Something went wrong.");
      }
    }
  };
};

const getJSONValidateErrorMessage = (errors) => {
  const getFlatErrorContent = (err) => {
    return (err || []).map((e) => (Array.isArray(e) ? e.join(" => ") : e));
  };
  return errors.map((err) => `${err[0]}: ${getFlatErrorContent(err[1])}`);
};

export const loadBotFromServer = (customerId, botId, callback) => {
  return async (dispatch, getState) => {
    const { customers } = getState().manager;

    if (customers[customerId] && customers[customerId].botsData[botId]) {
      const { latestVersion } = customers[customerId].botsData[botId];

      if (latestVersion) {
        try {
          const serverFiles = Object.values(latestVersion.files);

          if (serverFiles.indexOf("templates/bot.csv") > -1) {
            const response = await botManagerAPI.downloadTemplateFile(
              latestVersion.id,
              "templates/bot.csv"
            );

            let result = await importCsvFromStream(response.file_data, false);
            result = addAdditionalData(result.data);
            if (callback) {
              callback(result);
            }
          }
        } catch (error) {
          console.log(error);

          if (callback) {
            callback(null);
          }
        }
      }
    } else {
      if (callback) {
        callback(null);
      }
    }
  };
};

export const importCsvFromStream = (stream, fromLocalImport) => {
  return new Promise((resolve, reject) => {
    const config = {
      header: true,
      dynamicTyping: true,
      delimiter: ",",
      complete(results, file) {
        console.log("Parsing complete:", results, file);
        resolve(results);
      },
      skipEmptyLines: true,
      error: function () {
        console.error(arguments);
        reject(arguments);
      },
    };

    const unescapedStreams = stream.replace(UNESCAPE_CHAR_PATTERN, ($0) =>
      $0.replace("'", "")
    );

    Papa.parse(unescapedStreams, config);
  });
};

export const setBotUploading = (flag) => ({
  type: SET_BOT_UPLOADING,
  data: { flag },
});

export const uploadBot = (customerId, botId, isChecking, callback) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const state = getState();
        const { present } = state.solution;
        const { customers } = state.manager;

        let intentFileBlob = null;
        let entityFileBlob = null;

        const { latestVersion, hasDraft } =
          customers[customerId].botsData[botId];

        if (latestVersion) {
          const serverFiles = Object.values(latestVersion.files);

          if (serverFiles.indexOf("templates/intent.csv") > -1) {
            const intentCsvFileFromServer =
              await botManagerAPI.downloadTemplateFile(
                latestVersion.id,
                "templates/intent.csv"
              );

            intentFileBlob = new Blob([intentCsvFileFromServer.file_data], {
              type: "text/xml",
            });
          }

          if (serverFiles.indexOf("templates/entity.csv") > -1) {
            const entityCsvFileFromServer =
              await botManagerAPI.downloadTemplateFile(
                latestVersion.id,
                "templates/entity.csv"
              );
            entityFileBlob = new Blob([entityCsvFileFromServer.file_data], {
              type: "text/xml",
            });
          }

          const formData = new FormData();

          const binaryData = [];
          const csvJSON = [
            ...SYSTEM_NODES,
            ...parseSolutionDataToCSVData(present.solution),
          ];
          const filteredCSV = csvJSON.map((item) => {
            return Object.keys(item).reduce(
              (res, field) => {
                const header = CSVHeaders.find((h) => h.key === field);

                if (header) {
                  return {
                    ...res,
                    [header.label]: item[field],
                  };
                }

                return res;
              },
              Object.values(CSVHeaders).reduce(
                (sum, { label }) => ({ ...sum, [label]: null }),
                {}
              )
            );
          });
          const csv = Papa.unparse(filteredCSV);
          binaryData.push(csv);
          const csvBlob = new Blob(binaryData, { type: "text/plain" });

          formData.append("templateFile", csvBlob, "bot.csv");
          formData.append("trainingIntentFile", intentFileBlob, "intent.csv");
          formData.append("trainingEntityFile", entityFileBlob, "entity.csv");
          formData.append("simplifiedTrainingData", "simplified");

          let uploadToVersionId = null;
          if (hasDraft) {
            uploadToVersionId = latestVersion.id;
          } else {
            await botManagerAPI.createNewBotVersion(
              `${customerId}.${botId}`,
              latestVersion ? latestVersion.version : null
            );
            const res = await dispatch(initBot(customerId, botId));
            uploadToVersionId = res.botData.latestVersion.id;
          }

          const response = await botManagerAPI.uploadTemplate(
            uploadToVersionId,
            formData,
            5,
            () => {}
          ); // 5 = NLU_ENABLED

          const data = response.data;

          if (response.status !== 200) {
            // eslint-disable-next-line no-unused-vars
            reject(data.errors);
            callback(true, data.errors);
          } else {
            activityTracker.logEvent(
              activityTracker.eventTypeNames.SAVE_SOLUTION_CHANGES,
              {
                numberOfWarnings: 1,
                success: true,
                solutionName: `${state.designer.present.customerId}:${state.designer.present.botId}`,
              }
            );

            if (isChecking) {
              // Call /validate
              const { statusCode, data } = await botManagerAPI.validateVersion(
                uploadToVersionId
              );
              if (statusCode === 200) {
                callback(false, data);
              } else {
                callback(true, data.messages || []);
              }
            } else {
              callback(false);
            }
          }

          resolve();
        }
      } catch (err) {
        reject(
          "Internet connectivity has been lost! \nYour changes have not been saved. \nTo save your changes please click “Save”"
        );
        callback(true, [
          "Internet connectivity has been lost! \nYour changes have not been saved. \nTo save your changes please click “Save”",
        ]);
      }
    });
  };
};

export const setSystemNodes = (nodes) => ({
  type: SET_SYSTEM_NODES,
  data: { nodes },
});

export const uploadActionScript = (customerId, botId, script, callback) => {
  return async (dispatch, getState) => {
    const { customers } = getState().manager;

    if (customers[customerId] && customers[customerId].botsData[botId]) {
      const { latestVersion } = customers[customerId].botsData[botId];
      const draftVersion = latestVersion.id;

      try {
        const formData = new FormData();
        formData.append("scriptFile", script);

        const result = await botManagerAPI.uploadScript(draftVersion, formData);

        if (result.status === 200) {
          callback(true);
        } else {
          callback(false);
        }
      } catch (error) {
        console.log(error);
        callback(false);
      }
    }
  };
};

export const switchSolutionTab = (tab) => ({
  type: SWITCH_TAB,
  data: tab,
});

export const setSolutionErrors = (errors) => ({
  type: SET_SOLUTION_ERRORS,
  data: errors,
});

export const setPersistentMenus = (menus) => ({
  type: SET_PERSISTENT_MENUS,
  data: menus,
});

export const setGraphHasChanges = (has) => ({
  type: SET_GRAPH_HAS_CHANGES,
  data: has,
});

export const setSolutionFlags = (flags) => ({
  type: SET_SOLUTION_FLAGS,
  data: {
    flags,
  },
});
