export function decodeRichAssetContentSecure(richAssetContent) {
  return richAssetContent;
}

export function encodeRichAssetContentSecure(richAssetContent) {
  return richAssetContent;
}

export function validateRichAssetContentSecure(
  richAssetContent,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  let errors = {};
  let foundError = false;
  if (
    !richAssetContent &&
    (!validateOnlyTouched ||
      (validateOnlyTouched && touched_rich_asset_content))
  ) {
    errors = "A message is required";
    foundError = true;
  }
  return foundError ? errors : undefined;
}
