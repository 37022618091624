import {
  DEFAULT_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
  FILE_UPLOAD_TYPE_ACTION_NODE,
  FILE_UPLOAD_TYPE_DIRECT_POST,
} from "Designer/lib/defs";
import { validatorHTTPS } from "Designer/lib/validators";
import { addNode } from "Designer/Store/actions";

export function decodeRichAssetContentFileUpload(richAssetContent) {
  let newRichContent = null;
  try {
    newRichContent = JSON.parse(richAssetContent);
  } catch (e) {
    console.error(e);
    console.error(richAssetContent);
  }
  if (!newRichContent) {
    return {};
  }

  delete newRichContent.action_script;

  if (newRichContent.type === FILE_UPLOAD_TYPE_DIRECT_POST) {
    const headersObj = Object.assign({}, newRichContent.headers);
    newRichContent.headers = Object.keys(headersObj).map((key) => ({
      key,
      value: headersObj[key],
    }));
  }
  return newRichContent;
}

export function encodeRichAssetContentFileUpload(data, allNodes) {
  const richContent = Object.assign({}, data.rich_asset_content);

  if (richContent.type === FILE_UPLOAD_TYPE_ACTION_NODE) {
    const actionNode = allNodes.getNodeRead(data.next_nodes[0]);
    if (actionNode) {
      richContent.action_script = actionNode.data.command;
    }
  } else {
    const headersArray = richContent.headers.slice(0);
    richContent.headers = {};
    headersArray.forEach((i) => (richContent.headers[i.key] = i.value));
  }
  return JSON.stringify(richContent);
}

export function validateRichAssetContentFileUpload(
  data,
  touched,
  validateOnlyTouched
) {
  const errors = { rich_asset_content: {}, next_nodes: [] };
  let foundError = false;
  const richAssetContent = data.rich_asset_content;
  const touched_rich_asset_content = validateOnlyTouched
    ? touched.rich_asset_content
    : null;
  const next_nodes = data.next_nodes;
  const touched_next_nodes = validateOnlyTouched ? touched.next_nodes : null;
  if (richAssetContent) {
    if (
      (!validateOnlyTouched ||
        (validateOnlyTouched &&
          touched_rich_asset_content &&
          touched_rich_asset_content.upload_label)) &&
      !richAssetContent.upload_label
    ) {
      errors.rich_asset_content.upload_label = "Upload label is missing";
      foundError = true;
    }
    if (
      (!validateOnlyTouched ||
        (validateOnlyTouched &&
          touched_rich_asset_content &&
          touched_rich_asset_content.cancel_label)) &&
      !richAssetContent.cancel_label
    ) {
      errors.rich_asset_content.cancel_label = "Cancel label is missing";
      foundError = true;
    }
    if (
      !validateOnlyTouched ||
      (validateOnlyTouched &&
        touched_rich_asset_content &&
        touched_rich_asset_content.endpoint)
    ) {
      const urlError = validatorHTTPS(richAssetContent.endpoint);
      if (urlError) {
        errors.rich_asset_content.endpoint = urlError;
        foundError = true;
      }
    }
    if (richAssetContent.headers) {
      richAssetContent.headers.forEach((header, index) => {
        if (
          (!validateOnlyTouched ||
            (validateOnlyTouched &&
              touched_rich_asset_content &&
              touched_rich_asset_content[`header-key-${index}`])) &&
          !header.key
        ) {
          errors.rich_asset_content[`header-key-${index}`] =
            "Header key is missing";
          foundError = true;
        }
        if (
          (!validateOnlyTouched ||
            (validateOnlyTouched &&
              touched_rich_asset_content &&
              touched_rich_asset_content[`header-value-${index}`])) &&
          !header.value
        ) {
          errors.rich_asset_content[`header-value-${index}`] =
            "Header value is missing";
          foundError = true;
        }
      });
    }
  }
  if (
    (!validateOnlyTouched ||
      (validateOnlyTouched && touched_next_nodes && touched_next_nodes[0])) &&
    !next_nodes[0]
  ) {
    errors.next_nodes[0] = "Upload next node is missing";
    foundError = true;
  }
  if (
    (!validateOnlyTouched ||
      (validateOnlyTouched && touched_next_nodes && touched_next_nodes[1])) &&
    !next_nodes[1]
  ) {
    errors.next_nodes[1] = "Cancel next node is missing";
    foundError = true;
  }
  return foundError ? errors : undefined;
}

export function createFileUploadGroup(updatedNode, nodes, dispatch) {
  const updatedNodeData = updatedNode.data;
  const actionNodeNumber = nodes.getNewNodeNumber();
  updatedNodeData.next_nodes = [actionNodeNumber, null];
  updatedNode.meta.group_root = updatedNodeData.number;

  dispatch(
    addNode(
      "D",
      {
        x: updatedNode.meta.x - DEFAULT_NODE_WIDTH,
        y:
          updatedNode.meta.y +
          updatedNode.meta.height +
          3 * DEFAULT_NODE_HEIGHT,
      },
      "CommandName-Success",
      actionNodeNumber + 1,
      false,
      undefined,
      undefined,
      updatedNodeData.number
    )
  );

  dispatch(
    addNode(
      "D",
      {
        x: updatedNode.meta.x + DEFAULT_NODE_WIDTH,
        y:
          updatedNode.meta.y +
          updatedNode.meta.height +
          3 * DEFAULT_NODE_HEIGHT,
      },
      "CommandName-Fail",
      actionNodeNumber + 2,
      false,
      undefined,
      undefined,
      updatedNodeData.number
    )
  );

  dispatch(
    addNode(
      "A",
      {
        x: updatedNode.meta.x,
        y: updatedNode.meta.y + updatedNode.meta.height + DEFAULT_NODE_HEIGHT,
      },
      "ValidateFile",
      actionNodeNumber,
      false,
      [
        {
          name: "true",
          next_node: actionNodeNumber + 1,
        },
        { name: "false", next_node: actionNodeNumber + 2 },
      ],
      "ValidateFile",
      updatedNodeData.number
    )
  );
}
