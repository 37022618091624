// ------------------------------------
// Action Types
// ------------------------------------
const SELECT_SAMPLE_NODE = "SELECT_SAMPLE_NODE";
const UPDATE_SAMPLE_NODE = "UPDATE_SAMPLE_NODE";
const CLEAR_SAMPLE_NODE = "CLEAR_SAMPLE_NODE";
const ADD_CHILD_SAMPLE_NODE = "ADD_CHILD_SAMPLE_NODE";
const FORMAT_SAMPLE_NODE = "FORMAT_SAMPLE_NODE";
const SET_SAMPLE_NODE_FLAGS = "SET_SAMPLE_NODE_FLAGS";

export {
  SELECT_SAMPLE_NODE,
  UPDATE_SAMPLE_NODE,
  CLEAR_SAMPLE_NODE,
  ADD_CHILD_SAMPLE_NODE,
  FORMAT_SAMPLE_NODE,
  SET_SAMPLE_NODE_FLAGS,
};
