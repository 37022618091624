import "@babel/polyfill"; // This must be the first line in src/index.js
import "react-app-polyfill/ie11"; // This must be the second line in src/index.js

import config from "./config";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./Manager/Styles/Globals.scss";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { botUserSessionReducer, authClientWrapper } from "bot-user-session";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import managerReducer from "Manager/Store/reducer";
import designerReducer from "Designer/Store/reducer";
import designProReducer from "./Solution/store";
import activityTracker from "activityTracker";
import App from "./app";
import { setExpiredAuthenticationCallback } from "./bot-manager-api";

const OktaAuthConfig = config.OktaAuthConfig;
authClientWrapper.setAuthConfiguration(OktaAuthConfig);

const rootReducer = combineReducers({
  manager: managerReducer,
  designer: designerReducer,
  botUserSession: botUserSessionReducer,
  ...designProReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

setExpiredAuthenticationCallback(() => {
  window.location.pathname = `${config.homepage}/unauthorized`;
});

activityTracker.init();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
