import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { connect } from "react-redux";
import {
  doesUserHasRole,
  BOT_MANAGER_ROLES,
  PermissionDenied,
} from "bot-user-session";
import { Route, Redirect } from "react-router-dom";

import AllowedPath from "./AllowedPath";
import { setSolutionFlags } from "./Solution/store/solution/actions";
import { setSampleNodeFlags } from "./Solution/store/sampleNode/actions";

const PrivateRoute = withLDConsumer()(
  ({
    component: Component,
    isAuthenticated,
    authenticationError,
    flags,
    setSolutionFlags,
    setSampleNodeFlags,
    ...rest
  }) => {
    const isAllowed = isAuthenticated && doesUserHasRole(BOT_MANAGER_ROLES);

    useEffect(() => {
      setSolutionFlags(flags);
      setSampleNodeFlags(flags);
    }, [flags, setSolutionFlags, setSampleNodeFlags]);

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated && authenticationError) {
            return (
              <>
                <PermissionDenied />
              </>
            );
          }

          if (isAuthenticated) {
            return isAllowed ? (
              <>
                <Component {...props} />
              </>
            ) : (
              <AllowedPath {...props} />
            );
          }

          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  authenticationError: PropTypes.bool,
  location: PropTypes.object,
  setSolutionFlags: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setSolutionFlags: (flags) => dispatch(setSolutionFlags(flags)),
  setSampleNodeFlags: (flags) => dispatch(setSampleNodeFlags(flags)),
});

export default withLDConsumer()(
  connect(() => ({}), mapDispatchToProps)(PrivateRoute)
);
