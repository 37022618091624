/* eslint-disable no-empty */
import {
  RICH_ASSET_TYPE_IMAGEBUTTON,
  RICH_ASSET_TYPE_LISTPICKER,
} from "Designer/lib/defs";
import { validatorHTTPS } from "Designer/lib/validators";

export function decodeRichAssetContentButtonsOrList(
  richAssetContent,
  richAssetType
) {
  let ret = { isDynamic: false, options: [] };
  if (richAssetContent) {
    if (
      richAssetType === RICH_ASSET_TYPE_IMAGEBUTTON ||
      richAssetType === RICH_ASSET_TYPE_LISTPICKER
    ) {
      // we will try the new way if it works if not continue to the old way
      try {
        const obj = JSON.parse(richAssetContent);
        if (obj.type === "dynamic") {
          ret.isDynamic = true;
          ret.source_node = obj.source_node;
          ret.source_var = obj.source_var;
        } else {
          ret.options = obj.options.map((option) => ({
            name: option.label,
            image_url: option.image,
            alt_text: option.alt_text,
            next_node: Number(option.dest),
            description: option.description,
          }));
        }

        return ret;
      } catch (e) {}
    }

    const trimedContent = richAssetContent.trim();
    if (trimedContent.startsWith("{") && trimedContent.endsWith("}")) {
      const parts = richAssetContent.slice(1, -1).split("*");
      if (parts.length === 2) {
        ret = {
          isDynamic: true,
          sourceNode: Number(parts[0]),
          variable: parts[1],
          options: [],
        };
      }
    } else {
      const buttons = richAssetContent.split("|");

      buttons.forEach((button) => {
        const buttonParts = button.split("~");
        if (buttonParts.length === 2) {
          ret.options.push({
            name: buttonParts[0],
            next_node: Number(buttonParts[1]),
          });
        } else if (buttonParts.length === 3) {
          ret.options.push({
            name: buttonParts[0],
            next_node: Number(buttonParts[1]),
            image_url: buttonParts[2],
          });
        }
      });
    }
  }
  return ret;
}

export function encodeRichAssetContentButtonsOrList(
  richAssetContent,
  richAssetType
) {
  const ret = [];
  if (richAssetContent) {
    if (
      richAssetType === RICH_ASSET_TYPE_IMAGEBUTTON ||
      richAssetType === RICH_ASSET_TYPE_LISTPICKER
    ) {
      let obj = {};
      if (richAssetContent.isDynamic) {
        obj = {
          type: "dynamic",
          source_node: richAssetContent.source_node,
          source_var: richAssetContent.source_var,
        };
      } else {
        obj = {
          type: "static",
          options: [],
        };
        richAssetContent.options.forEach((button, index) => {
          obj.options[index] = {
            label: button.name,
            image: button.image_url || "",
            alt_text: button.alt_text || "",
            dest:
              richAssetType === RICH_ASSET_TYPE_LISTPICKER
                ? `${button.next_node}`
                : button.next_node,
          };
          if (richAssetType === RICH_ASSET_TYPE_LISTPICKER) {
            obj.options[index].description = button.description || "";
          }
        });
      }
      return JSON.stringify(obj);
    }

    if (!richAssetContent.isDynamic) {
      return richAssetContent.options
        .map((button) => `${button.name}~${button.next_node}`)
        .join("|");
    }
    return `{${richAssetContent.sourceNode}*${richAssetContent.variable}}`;
  }
  return ret;
}

export function validateRichAssetContentButtonsOrList(
  richAssetContent,
  richAssetType,
  allNodes,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  let errors = [];
  let foundError = false;
  if (
    richAssetContent &&
    !richAssetContent.isDynamic &&
    Array.isArray(richAssetContent.options)
  ) {
    richAssetContent.options.forEach((button, index) => {
      errors[index] = {};
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index] &&
            touched_rich_asset_content[index].name)) &&
        !button.name
      ) {
        errors[index].name = "Option name is required";
        foundError = true;
      }
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index] &&
            touched_rich_asset_content[index].next_node)) &&
        (!button.next_node || !allNodes.getNodeRead(button.next_node))
      ) {
        errors[index].next_node = "A next node is required";
        foundError = true;
      }
      if (
        richAssetType === RICH_ASSET_TYPE_IMAGEBUTTON ||
        richAssetType === RICH_ASSET_TYPE_LISTPICKER
      ) {
        if (
          (!validateOnlyTouched ||
            (validateOnlyTouched &&
              Array.isArray(touched_rich_asset_content) &&
              touched_rich_asset_content.length >= index + 1 &&
              touched_rich_asset_content[index] &&
              touched_rich_asset_content[index].image_url)) &&
          !button.image_url
        ) {
          const urlError = validatorHTTPS(button.image_url);
          if (urlError) {
            errors[index].image_url = urlError;
            foundError = true;
          }
        }
      }
    });
  } else if (
    richAssetContent &&
    richAssetContent.isDynamic &&
    !validateOnlyTouched
  ) {
    errors = {};
    if (!richAssetContent.sourceNode) {
      errors.sourceNode = "A source node is required";
    } else {
      const sourceNode = allNodes.getNodeRead(richAssetContent.sourceNode);
      if (!sourceNode) {
        errors.sourceNode = "A source node is required";
      } else if (!richAssetContent.variable) {
        errors.variable = "Output variable is required";
      } else {
        const { output } = sourceNode.data;
        if (!output) {
          errors.variable = "Source node does not have any output variable";
        } else {
          const outputsArr = output.split(",").map((item) => item.trim());
          if (!outputsArr.includes(richAssetContent.variable)) {
            errors.variable = "Output variable is required";
          }
        }
      }
    }
    foundError = Object.keys(errors).length !== 0;
  }
  return foundError ? errors : undefined;
}
