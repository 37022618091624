export const UNDO = "UNDO";
export const REDO = "REDO";

export const UPDATE_NODES = "UPDATE_NODES";
export const NODE_SELECTED = "NODE_SELECTED";
export const EDGE_SELECTED = "EDGE_SELECTED";
export const EDGE_DESELECTED = "EDGE_DESELECTED";
export const OPEN_GROUP = "OPEN_GROUP";
export const EMBED_OPTION_SELECT = "EMBED_OPTION_SELECT";
export const UPDATE_ZOOM_LEVEL = "UPDATE_ZOOM_LEVEL";
export const UPDATE_SVG_AREA = "update_Svg_Area";
export const UNSET_ALIGN_TO_ROOT = "UNSET_ALIGN_TO_ROOT";
export const SET_GLOBAL_VARIABLES = "SET_GLOBAL_VARIABLES";
export const SET_BOT_ID = "SET_BOT_ID";
export const CLEAR_DESIGNER_DATA = "CLEAR_DESIGNER_DATA";
export const BOT_IS_UPLOADIND = "BOT_IS_UPLOADIND";
export const BOT_WAS_UPLOADED = "BOT_WAS_UPLOADED";
export const BOT_WAS_NOT_UPLOADED = "BOT_WAS_NOT_UPLOADED";

export const OPEN_LEFT_SIDE_DRAWER = "OPEN_LEFT_SIDE_DRAWER";
export const CLOSE_LEFT_SIDE_DRAWER = "CLOSE_LEFT_SIDE_DRAWER";
export const OPEN_RIGHT_SIDE_DRAWER = "OPEN_RIGHT_SIDE_DRAWER";
export const CLOSE_RIGHT_SIDE_DRAWER = "CLOSE_RIGHT_SIDE_DRAWER";

export const ADD_TO_CLIPBOARD = "ADD_TO_CLIPBOARD";
export const REMOVE_FROM_CLIPBOARD = "REMOVE_FROM_CLIPBOARD";
