import { validatorHTTPS } from "Designer/lib/validators";
import { RICH_ASSET_TYPE_IMAGE } from "../../../lib/defs";

export function decodeRichAssetContentRichMedia(
  richAssetContent,
  richAssetType
) {
  if (richAssetType === RICH_ASSET_TYPE_IMAGE) {
    try {
      // try the new type
      return JSON.parse(richAssetContent);
    } catch (e) {
      console.log(e);
    }
  }

  return {
    url: richAssetContent,
    alt_text: "",
    hasError: false,
    type: "static",
  };
}

export function encodeRichAssetContentRichMedia(
  richAssetContent,
  richAssetType
) {
  if (richAssetType === RICH_ASSET_TYPE_IMAGE) {
    const ret = Object.assign({}, richAssetContent);
    delete ret.hasError;
    return JSON.stringify(ret);
  }
  return richAssetContent.url;
}

export function validateRichAssetContentRichMedia(
  richAssetContent,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  const errors = {};
  let foundError = false;
  if (
    richAssetContent &&
    (!validateOnlyTouched ||
      (validateOnlyTouched &&
        touched_rich_asset_content &&
        touched_rich_asset_content.url))
  ) {
    const urlError = validatorHTTPS(richAssetContent.url);
    if (urlError) {
      errors.url = urlError;
      foundError = true;
    }
  }
  return foundError ? errors : undefined;
}
