/* eslint-disable no-empty */
import { RICH_ASSET_TYPE_PERSISTENT_MENU } from "Designer/lib/defs";

export function decodeRichAssetContentPersistentMenu(
  richAssetContent,
  richAssetType
) {
  let ret = { isDynamic: false, options: [] };
  if (richAssetContent && richAssetType === RICH_ASSET_TYPE_PERSISTENT_MENU) {
    try {
      const obj = JSON.parse(richAssetContent);

      if (obj.type === "dynamic") {
        ret.isDynamic = true;
        ret.source_node = obj.source_node;
        ret.source_var = obj.source_var;
      } else {
        ret.options = obj.options.map((option) => ({
          name: option.label,
          next_node: Number(option.dest),
          set_value: option.set_value || "",
        }));
      }

      return ret;
    } catch (e) {}
  }
  return ret;
}

export function encodeRichAssetContentPersistentMenu(
  richAssetContent,
  richAssetType
) {
  const ret = [];
  if (richAssetContent && richAssetType === RICH_ASSET_TYPE_PERSISTENT_MENU) {
    let obj = {};
    if (richAssetContent.isDynamic) {
      obj = {
        type: "dynamic",
        source_node: richAssetContent.source_node,
        source_var: richAssetContent.source_var,
      };
    } else {
      obj = {
        type: "static",
      };
      obj.options = richAssetContent.options.map((menu) => ({
        label: menu.name,
        dest: menu.next_node,
        set_value: menu.name.toLowerCase().split(" ").join("_"),
      }));
    }
    return JSON.stringify(obj);
  }
  return ret;
}

export function validateRichAssetContentPersistentMenu(
  richAssetContent,
  allNodes,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  let errors = [];
  let foundError = false;
  if (
    richAssetContent &&
    !richAssetContent.isDynamic &&
    Array.isArray(richAssetContent.options)
  ) {
    richAssetContent.options.forEach((menu, index) => {
      errors[index] = {};
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index] &&
            touched_rich_asset_content[index].name)) &&
        !menu.name
      ) {
        errors[index].name = "Menu Option name is required";
        foundError = true;
      }
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index] &&
            touched_rich_asset_content[index].next_node)) &&
        (!menu.next_node || !allNodes.getNodeRead(menu.next_node))
      ) {
        errors[index].next_node = "A next node is required";
        foundError = true;
      }
    });
  }
  return foundError ? errors : undefined;
}
