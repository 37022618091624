import {
  SELECT_SAMPLE_NODE,
  UPDATE_SAMPLE_NODE,
  CLEAR_SAMPLE_NODE,
  ADD_CHILD_SAMPLE_NODE,
  FORMAT_SAMPLE_NODE,
  SET_SAMPLE_NODE_FLAGS,
} from "./actionTypes";

// ------------------------------------
// Actions
// ------------------------------------
export const selectSampleNode = (node) => ({
  type: SELECT_SAMPLE_NODE,
  data: {
    node,
  },
});

export const updateSampleNode = (type, data, isWorkflow = false) => {
  return {
    type: UPDATE_SAMPLE_NODE,
    data: {
      type,
      data,
      isWorkflow,
    },
  };
};

export const clearSampleNode = () => ({
  type: CLEAR_SAMPLE_NODE,
});

export const addChildNodeToSample = (node, pos) => ({
  type: ADD_CHILD_SAMPLE_NODE,
  data: {
    node,
    pos,
  },
});

export const formatSampleNode = (
  type,
  isWorkflow = false,
  customerId = "",
  botId = ""
) => ({
  type: FORMAT_SAMPLE_NODE,
  data: {
    type,
    isWorkflow,
    customerId,
    botId,
  },
});

export const setSampleNodeFlags = (flags) => ({
  type: SET_SAMPLE_NODE_FLAGS,
  data: {
    flags,
  },
});
