export const DEFAULT_NODE_WIDTH = 200;
export const DEFAULT_NODE_HEIGHT = 50;
export const MAX_TREE_DEPTH = 20;

export const RICH_ASSET_TYPE_BUTTON = "button";
export const RICH_ASSET_TYPE_SELECTION = "selection";
export const RICH_ASSET_TYPE_LISTPICKER = "listpicker";
export const RICH_ASSET_TYPE_IMAGEBUTTON = "imagebutton";
export const RICH_ASSET_TYPE_DATEPICKER = "datepicker";
export const RICH_ASSET_TYPE_TIMEPICKER = "timepicker";
export const RICH_ASSET_TYPE_SECURE = "secure";
export const RICH_ASSET_TYPE_CAROUSEL = "carousel";
export const RICH_ASSET_TYPE_DEEPLINK = "deeplink";
export const RICH_ASSET_TYPE_WEBVIEW = "webview";
export const RICH_ASSET_TYPE_DOCUMENT = "document";
export const RICH_ASSET_TYPE_IMAGE = "image";
export const RICH_ASSET_TYPE_VIDEO = "video";
export const RICH_ASSET_TYPE_LOGIN_BUTTON = "login_button";
export const RICH_ASSET_TYPE_LOCATION = "location";
export const RICH_ASSET_TYPE_FILE_UPLOAD = "file_upload";
export const RICH_ASSET_TYPE_PERSISTENT_MENU = "persistent_menu";

export const RICH_ASSET_TYPE_IMAGE_STR = "Image";
export const RICH_ASSET_TYPE_VIDEO_STR = "Video";

export const UserResponseOptions = [
  { value: null, name: "None" },
  { value: RICH_ASSET_TYPE_BUTTON, name: "Button" },
  { value: RICH_ASSET_TYPE_IMAGEBUTTON, name: "Button" },
  { value: RICH_ASSET_TYPE_SELECTION, name: "List Picker" },
  { value: RICH_ASSET_TYPE_LISTPICKER, name: "List Picker" },
  { value: RICH_ASSET_TYPE_IMAGE, name: "Rich Media" },
  { value: RICH_ASSET_TYPE_VIDEO, name: "Rich Media" },
  { value: RICH_ASSET_TYPE_DATEPICKER, name: "Date Picker" },
  { value: RICH_ASSET_TYPE_TIMEPICKER, name: "Time Picker" },
  { value: RICH_ASSET_TYPE_CAROUSEL, name: "Carousel" },
  { value: RICH_ASSET_TYPE_DOCUMENT, name: "Document" },
  { value: RICH_ASSET_TYPE_FILE_UPLOAD, name: "File Upload" },
  { value: RICH_ASSET_TYPE_LOCATION, name: "Location" },
  { value: RICH_ASSET_TYPE_WEBVIEW, name: "Webview" },
  { value: RICH_ASSET_TYPE_SECURE, name: "Sensitive Data" },
  { value: RICH_ASSET_TYPE_LOGIN_BUTTON, name: "Authentication" },
];
export const UserResponseOptionsWithNoDuplication = [
  { value: null, name: "None" },
  { value: RICH_ASSET_TYPE_BUTTON, name: "Button" },
  { value: RICH_ASSET_TYPE_SELECTION, name: "List Picker" },
  { value: RICH_ASSET_TYPE_IMAGE, name: "Rich Media" },
  { value: RICH_ASSET_TYPE_DATEPICKER, name: "Date Picker" },
  { value: RICH_ASSET_TYPE_TIMEPICKER, name: "Time Picker" },
  { value: RICH_ASSET_TYPE_CAROUSEL, name: "Carousel" },
  { value: RICH_ASSET_TYPE_DOCUMENT, name: "Document" },
  { value: RICH_ASSET_TYPE_FILE_UPLOAD, name: "File Upload" },
  { value: RICH_ASSET_TYPE_LOCATION, name: "Location" },
  { value: RICH_ASSET_TYPE_WEBVIEW, name: "Webview" },
  { value: RICH_ASSET_TYPE_SECURE, name: "Sensitive Data" },
  { value: RICH_ASSET_TYPE_LOGIN_BUTTON, name: "Authentication" },
];

export const DYNAMIC_SUPPORTED_RICH_ASSET_TYPES = [
  RICH_ASSET_TYPE_BUTTON,
  RICH_ASSET_TYPE_SELECTION,
  RICH_ASSET_TYPE_LISTPICKER,
  RICH_ASSET_TYPE_IMAGE,
  RICH_ASSET_TYPE_CAROUSEL,
  RICH_ASSET_TYPE_DOCUMENT,
  RICH_ASSET_TYPE_WEBVIEW,
];

export const BEHAVIORS_MULTIPLE_CHOICE = "multiple_choice";
export const BEHAVIORS_DISABLE_INPUT = "disable_input";
export const BEHAVIORS_XFER_TO_AGENT = "xfer_to_agent";
export const BEHAVIORS_INLINE = "inline";

export const BEHAVIORS_MULTIPLE_CHOICE_STR = "Multiple choice";
export const BEHAVIORS_DISABLE_INPUT_STR = "Disable user input";
export const BEHAVIORS_XFER_TO_AGENT_STR = "Transfer to agent";
export const BEHAVIORS_INLINE_STR = "Inline";

export const FILE_UPLOAD_TYPE_DIRECT_POST = "direct_post";
export const FILE_UPLOAD_TYPE_ACTION_NODE = "action_node";

export const FILE_UPLOAD_TYPE_DIRECT_POST_STR = "Direct Post";
export const FILE_UPLOAD_TYPE_ACTION_NODE_STR = "Action Node";

export const ONLY_HEADER_ZOOM = 0.25;

export const NODE_STATE = {
  NODE_STATE_DEFAULT: 0,
  NODE_STATE_SELECTED: 1,
  NODE_STATE_CONNECTED: 2,
  NODE_STATE_NOT_CONNECTED: 3,
};

export const EDGE_STATE = {
  EDGE_STATE_DEFAULT: 0,
  EDGE_STATE_NOT_CONNECTED: 1,
  EDGE_STATE_INCOMING: 2,
  EDGE_STATE_OUTGOING: 3,
};

export const PRESERVE_NODE_NUMBERS = {
  RootGroup: -5000,
  HiddenGroup: -5001,
  HyperspaceAgent: -1800,
  HyperspaceStartOver: -929,
  HyperspaceEnd: -212,
  StartNode: 1,
  PersistentMenuNode: -411,
};
