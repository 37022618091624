import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PreviewModalNew from "../PreviewPanel/PreviewModalNew";
import PassthroughModal from "../PreviewPanel/PassthroughModal";

import { setPreviewModalOpen } from "../../../Store/actions";

import "./style.scss";

const WebChatPreview = ({
  previewModalOpen,
  previewModalConfig,
  customers,
  flags,
  botUserSession,
  setPreviewModalOpen,
}) => {
  const [showPassthroughModal, setShowPassthroughModal] = useState(false);
  const [sandboxPreviewMetadata, setSandboxPreviewMetadata] = useState(
    `{"preview_metadata": [{"onlyedit": "thesevalues"}]}`
  );
  const [parametersError, setParametersError] = useState(false);

  const previewRefreshRef = useRef(null);

  const customer = useMemo(() => {
    if (isEmpty(customers) || isEmpty(previewModalConfig)) {
      return null;
    }

    return customers[previewModalConfig.customerId];
  }, [customers, previewModalConfig]);

  const botData = useMemo(() => {
    if (isEmpty(customer)) {
      return null;
    }

    return customer ? customer.botsData[previewModalConfig.botId] : null;
  }, [customer, previewModalConfig]);

  const closePreviewHandler = (Pypestream) => {
    if (typeof Pypestream === "function") {
      Pypestream.shutdown();
    }
    setPreviewModalOpen(false, previewModalConfig);
  };

  const refreshPreviewModal = () => {
    console.log("refresh clicked");
  };

  const updateMetadata = (metadata) => {
    setSandboxPreviewMetadata(
      metadata != "undefined" && metadata != undefined
        ? `{"preview_metadata": ${JSON.stringify([...metadata])}}`
        : `{"preview_metadata": [{"title": "value"}]}`
    );
  };

  const handleSubmitPassthrough = () => {
    previewRefreshRef?.current?.handleSubmitPassthrough();
    try {
      JSON.parse(sandboxPreviewMetadata);
      setShowPassthroughModal(false);
      setParametersError(false);
    } catch (e) {
      setParametersError(true);
    }
  };

  const onChangeParameters = (value) => {
    setSandboxPreviewMetadata(value);
  };

  useEffect(() => {
    if (!previewModalOpen) {
      document.body.classList.remove("has-preview-panel-active");
    }
  }, [previewModalOpen]);

  return customer && botData ? (
    <React.Fragment>
      <PreviewModalNew
        ref={previewRefreshRef}
        title="Preview Conversation"
        envs={["live", "sandbox"]}
        show={previewModalOpen}
        customer={customer}
        botData={botData}
        editFlag={flags?.pe18176}
        botUserId={botUserSession?.user?.user_id}
        sandboxPreviewMetadata={sandboxPreviewMetadata}
        handleClose={closePreviewHandler}
        handleRefresh={refreshPreviewModal}
        handleEdit={() => setShowPassthroughModal(true)}
        updateMetadata={updateMetadata}
        {...previewModalConfig}
      />
      {showPassthroughModal && (
        <PassthroughModal
          passthroughModal={showPassthroughModal}
          sandboxPreviewMetadata={sandboxPreviewMetadata}
          parametersError={parametersError}
          onClose={() => setShowPassthroughModal(false)}
          submitPassthrough={handleSubmitPassthrough}
          onChange={(e) => onChangeParameters(e.target.value)}
        />
      )}
    </React.Fragment>
  ) : null;
};

const mapStateToProps = (state) => {
  const { previewModalOpen, previewModalConfig } = state.manager;

  return {
    previewModalOpen,
    previewModalConfig,
    customers: state.manager.customers,
    botUserSession: state.botUserSession,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPreviewModalOpen: (open, config) =>
    dispatch(setPreviewModalOpen(open, config)),
});

WebChatPreview.propTypes = {
  previewModalOpen: PropTypes.bool,
  previewModalConfig: PropTypes.object,
  flags: PropTypes.object,
  customers: PropTypes.array,
  botUserSession: PropTypes.object,
  setPreviewModalOpen: PropTypes.object,
};

WebChatPreview.defaultProps = {
  previewModalOpen: false,
  previewModalConfig: {},
  flags: {},
  customers: [],
  botUserSession: {},
  setPreviewModalOpen: () => {},
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(WebChatPreview)
);
