import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Login,
  userLogout,
  doesUserHasRole,
  BOT_MANAGER_ROLES,
} from "bot-user-session";
import { createTheme } from "@material-ui/core/styles";
import NotFound from "Manager/Components/NotFound";
import Loader from "Manager/Components/Loader";
import { setNotification } from "Manager/Store/actions";
import config from "./config";
import { MuiThemeProvider } from "@material-ui/core";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { Notification } from "ui-elements";
import * as LDClient from "launchdarkly-js-client-sdk";
import activityTracker from "./activityTracker";
import PrivateRoute from "./PrivateRoute";
import ManagerRoute from "./ManagerRoute";

import WebChatPreview from "./Manager/Components/modals/WebChatPreview";
export const ldClient = LDClient.initialize(config.launchDarklyClientId, {});

const Unauthorized = React.lazy(() =>
  import("Manager/Components/Unauthorized")
);
const Customers = React.lazy(() => import("Manager/Components/Customers"));
const Customer = React.lazy(() => import("Manager/Components/Customer"));
const Bot = React.lazy(() => import("Manager/Components/Bot/Bot"));
const CreateOrEditInterface = React.lazy(() =>
  import("./Manager/Components/Bot/ChannelsOrInterfaces/CreateOrEditInterface")
);
const SelectChannelInterface = React.lazy(() =>
  import("./Manager/Components/Bot/SelectChannel/SelectChannelInterface")
);
const EditActions = React.lazy(() =>
  import("./Manager/Components/Bot/EditActions")
);
const DesignBoard = React.lazy(() => import("Designer/Components/designBoard"));
const Solution = React.lazy(() => import("Solution"));

const lazyRoutes = [
  { path: "/unauthorized", exact: false, component: Unauthorized },
  { path: "/", exact: true, component: Customers },
  { path: "/customers", exact: false, component: Customers },
  { path: "/customer/:customerId", exact: false, component: Customer },
  { path: "/bot/:customerId/:botId", exact: true, component: Bot },
  {
    path: "/bot/:customerId/:botId/createNewInterface",
    exact: false,
    component: CreateOrEditInterface,
  },
  {
    path: "/bot/:customerId/:botId/editInterface/:conversationId",
    exact: false,
    component: CreateOrEditInterface,
  },
  {
    path: "/bot/:customerId/:botId/channel",
    exact: false,
    component: SelectChannelInterface,
  },
  {
    path: "/bot/:customerId/:botId/channel/:channelId",
    exact: false,
    component: SelectChannelInterface,
  },
  {
    path: "/bot/:customerId/:botId/interface/:interfaceId",
    exact: false,
    component: SelectChannelInterface,
  },
  {
    path: "/bot/:customerId/:botId/editAction/:action",
    exact: false,
    component: EditActions,
  },
  {
    path: "/designer/:customerId/:botId",
    exact: false,
    component: DesignBoard,
    private: true,
  },
  {
    path: "/solution/:customerId/:botId",
    exact: false,
    component: Solution,
    private: true,
  },
];

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      LdProvider: null,
    };
    this.Logout = this.Logout.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isAuthenticated &&
      this.props.isAuthenticated &&
      doesUserHasRole(BOT_MANAGER_ROLES)
    ) {
      const { user } = this.props;
      const userAttributes = user && user.optimizelyUserAttributes;

      ldClient.identify({
        key: user.user_id,
        email: userAttributes.email,
        custom: {
          ...userAttributes,
        },
      });
      asyncWithLDProvider({
        clientSideID: config.launchDarklyClientId,
        user: {
          key: user.user_id,
          email: userAttributes.email,
          custom: {
            ...userAttributes,
          },
        },
        options: {
          /* ... */
        },
      })
        .then((provider) => {
          this.setState({ LdProvider: provider });
        })
        .catch((e) => console.log(e));

      if (window.Appcues) {
        window.Appcues.identify(user.user_id, { ...userAttributes });
      }
      activityTracker.mixpanel.identify(userAttributes.email);

      const keysWithNoEmail = Object.keys(userAttributes).filter(
        (key) => key !== "email"
      );
      const userAttributesWithNoEmail = keysWithNoEmail.reduce((acc, key) => {
        acc[key] = userAttributes[key];
        return acc;
      }, {});

      activityTracker.mixpanel.people.set({
        ...userAttributesWithNoEmail,
        $email: userAttributes.email,
        $name: user.name,
      });
    }
  }

  Logout() {
    this.props.onUserLogout();
    return <Redirect to="/login" />;
  }

  render() {
    const {
      isAuthenticated,
      authenticationError,
      openNotification,
      notificationTitle,
      notificationType,
      notificationDuration,
      notificationIcon,
    } = this.props;
    const { LdProvider } = this.state;
    const style = {};
    if (!isAuthenticated) {
      style.backgroundColor = "#f8f8f8";
    }

    const theme = createTheme({
      props: {
        // Name of the component
        MuiButtonBase: {
          // The properties to apply
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
    });

    const routerComponent = (
      <Router basename={config.homepage} onUpdate={() => window.Appcues.page()}>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path="/login"
              render={(props) => (
                <Login {...props} error={authenticationError} />
              )}
            />
            <this.Logout path="/logout" />
            <Route
              path="/activate/:token?"
              exact={false}
              render={(props) => (
                <Login {...props} activate={true} title="Pypestream" />
              )}
            />
            {lazyRoutes.map((route) => {
              if (route.private) {
                return (
                  <PrivateRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    isAuthenticated={isAuthenticated}
                    authenticationError={authenticationError}
                  />
                );
              }
              return (
                <ManagerRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  isAuthenticated={isAuthenticated}
                  authenticationError={authenticationError}
                />
              );
            })}
            <Route component={NotFound} />
          </Switch>
          <WebChatPreview />
        </React.Suspense>
      </Router>
    );

    return (
      <MuiThemeProvider theme={theme}>
        {LdProvider ? (
          <LdProvider>{routerComponent}</LdProvider>
        ) : (
          <Fragment>{routerComponent}</Fragment>
        )}
        <Notification
          open={openNotification}
          title={
            <div dangerouslySetInnerHTML={{ __html: notificationTitle }} />
          }
          type={notificationType}
          autoHideDuration={notificationDuration}
          icon={notificationIcon}
          onClose={() =>
            this.props.setNotification({ openNotification: false })
          }
        />
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  authenticationError: PropTypes.bool,
  openNotification: PropTypes.bool,
  notificationTitle: PropTypes.string,
  notificationType: PropTypes.string,
  notificationIcon: PropTypes.string,
  notificationDuration: PropTypes.number,
  getEnvsFromBack: PropTypes.func,
  onUserLogout: PropTypes.func,
  setNotification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.botUserSession.isAuthenticated,
    authenticationError: state.botUserSession.error !== null,
    user: state.botUserSession.user,
    openNotification: state.manager.openNotification,
    notificationDuration: state.manager.notificationDuration,
    notificationTitle: state.manager.notificationTitle,
    notificationType: state.manager.notificationType,
    notificationIcon: state.manager.notificationIcon,
  };
}

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => {
    dispatch(userLogout());
  },
  setNotification: (data) => dispatch(setNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
