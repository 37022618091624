import React from "react";
import PropTypes from "prop-types";
import { Modal as ModalUI, TextField } from "ui-elements";

const PassthroughModal = ({
  sandboxPreviewMetadata,
  parametersError,
  passthroughModal,
  onClose,
  submitPassthrough,
  onChange,
}) => {
  return (
    <ModalUI
      hasOverlayBackground={false}
      open={passthroughModal}
      size="medium"
      valign="center"
      onClose={onClose}
    >
      <ModalUI.Header>
        <ModalUI.Title>Initializing Data</ModalUI.Title>
      </ModalUI.Header>
      <ModalUI.Content>
        <ModalUI.Text>
          This data is given to a microapp before it starts, and typically
          originates from an API call or from the host website.
        </ModalUI.Text>
        <TextField
          value={sandboxPreviewMetadata}
          label="Parameters"
          variant="outlined"
          multiline
          rowsMax={5}
          error={parametersError}
          helperText={"Data must be provided in valid JSON format"}
          onChange={onChange}
        />
      </ModalUI.Content>
      <ModalUI.Footer>
        <ModalUI.PrimaryButton onClick={submitPassthrough}>
          Save and Restart
        </ModalUI.PrimaryButton>
      </ModalUI.Footer>
    </ModalUI>
  );
};

PassthroughModal.propTypes = {
  sandboxPreviewMetadata: PropTypes.string,
  parametersError: PropTypes.bool,
  passthroughModal: PropTypes.bool,
  onClose: PropTypes.func,
  submitPassthrough: PropTypes.func,
  onChange: PropTypes.func,
};

export default PassthroughModal;
