/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { PreviewPanel, DotMenu } from "ui-elements";

import config from "../../../../config";
import botManagerAPI from "../../../../bot-manager-api";
import Loader from "../../Loader";

import "./Preview.scss";

const PreviewModalNew = React.forwardRef(
  (
    {
      customer,
      botData,
      show,
      title,
      handleClose,
      handleEdit,
      variant,
      editFlag,
      botUserId,
      updateMetadata,
      sandboxPreviewMetadata,
    },
    ref
  ) => {
    const [previewData, setPreviewData] = useState({});
    const [fetchingWidget, setFetchingWidget] = useState(false);
    const [passthroughData, setPassthroughData] = useState({});
    const [pypeId, setPypeId] = useState("");
    const [streamId, setStreamId] = useState("");

    const refTimer = useRef(null);

    const scriptSrc = useMemo(() => {
      return `${config.webserviceEnvConfig.sandbox.wcwLauncherUrl}/api-launcher.js`.replace(
        "webchat",
        "web"
      );
    }, []);

    const menus = useMemo(() => {
      return editFlag
        ? [
            { menu: "Edit Initializing Data", value: "edit" },
            { menu: "Restart Microapp", value: "restart" },
          ]
        : [{ menu: "Restart Microapp", value: "restart" }];
    }, [editFlag]);

    const updatePreviewMetaData = async (widgetData) => {
      let resPassthroughData = "";

      try {
        const { customerName, botName } = botData.latestVersion;
        const pype_id = widgetData?.data[0]?.pype_id;
        const stream_id = widgetData?.data[0]?.stream_ids[0];
        setPypeId(pype_id);
        setStreamId(stream_id);

        const resMetadata = await botManagerAPI.getSolutionMetadata(
          "sandbox",
          botUserId,
          pype_id,
          stream_id
        );
        const metadata = resMetadata?.data?.preview_metadata ?? [];
        if (metadata && metadata != "undefined" && metadata != undefined) {
          const metadataMap = (metadata || []).reduce(
            (res, item) => ({
              ...res,
              [Object.keys(item)]: Object.values(item)[0],
            }),
            {}
          );
          resPassthroughData = JSON.stringify({
            preview: `${customerName}.${botName}`,
            ...(metadataMap ?? {}),
          });
          updateMetadata(metadata);
        } else {
          resPassthroughData = JSON.stringify({
            preview: `${customerName}.${botName}`,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPassthroughData(resPassthroughData);
      }

      return resPassthroughData;
    };

    const launchWidget = async (pData, pThroughData) => {
      const windowExists = typeof window.parent.Pypestream !== "undefined";

      if (!pData.error_reason) {
        if (windowExists) {
          if (refTimer?.current) {
            clearTimeout(refTimer?.current);
          }

          const Pypestream = window.parent.Pypestream;
          if (pData) {
            const config = {
              passthrough: pThroughData || "",
              APP_ID: pData[0].widget_ids[0],
              gtm_id: "GTM-P6DV8B4",
            };

            try {
              await Pypestream(
                "boot",
                config,
                document.getElementById("previewContainer")
              );
              Pypestream.show();
              setFetchingWidget(false);
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          refTimer.current = setTimeout(() => {
            launchWidget(pData, pThroughData);
          }, 2000);
        }
      }
    };

    const reloadWidget = async () => {
      try {
        const { latestVersion } = botData;
        const { id: latestVersionId, customerName, botName } = latestVersion;
        const customerGesId = customer.gesId;
        const currentEnv = "sandbox";
        const body = { customerName, botName };

        setFetchingWidget(true);

        await botManagerAPI.setPreviewWidget(latestVersionId);
        const widgetData = await botManagerAPI.getPreviewWidget(
          customerGesId,
          currentEnv,
          body
        );
        const resPassthroughData = await updatePreviewMetaData(widgetData);
        setPreviewData(widgetData.data);
        await launchWidget(widgetData.data, resPassthroughData);
      } catch (error) {
        console.log(error);
        setFetchingWidget(false);
      }
    };

    useEffect(() => {
      if (show) {
        reloadWidget();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const onClose = () => {
      if (window.parent?.Pypestream) {
        handleClose(window.parent?.Pypestream);
      }
    };

    const handleSubmitPassthrough = async () => {
      const { latestVersion } = botData;
      const { customerName, botName } = latestVersion;

      try {
        JSON.parse(sandboxPreviewMetadata);
        const res = await botManagerAPI.updateSolutionMetadata(
          "sandbox",
          botUserId,
          pypeId,
          streamId,
          sandboxPreviewMetadata
        );

        const updatedMetadata = {};
        const parsedMetadata = JSON.parse(
          sandboxPreviewMetadata
        ).preview_metadata;
        parsedMetadata.forEach((item) => {
          const key = Object.keys(item);
          const value = Object.values(item);
          updatedMetadata[key] = value[0];
        });

        if (res.status === 201) {
          setPassthroughData(
            JSON.stringify({
              preview: `${customerName}.${botName}`,
              ...updatedMetadata,
            })
          );
          handleRefresh();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleRefresh = () => {
      const windowExists = typeof window.parent.Pypestream !== "undefined";

      if (windowExists) {
        const { Pypestream } = window.parent;
        Pypestream.shutdown();
        reloadWidget();
      }
    };

    const onMenuClick = (menu) => {
      switch (menu.value) {
        case "edit":
          handleEdit();
          break;
        case "restart":
          handleRefresh();
          break;
        default:
          break;
      }
    };

    React.useImperativeHandle(ref, () => ({
      handleSubmitPassthrough: handleSubmitPassthrough,
    }));

    return (
      <div>
        <Helmet>
          {scriptSrc && (
            <script id="pypesScriptPreview" src={scriptSrc} async={true} />
          )}
        </Helmet>
        <PreviewPanel
          variant={variant}
          open={show}
          extra={
            <DotMenu
              closeOnMouseLeave={false}
              iconName="more"
              menus={menus}
              onClick={(menu) => onMenuClick(menu)}
              placement="bottom"
            />
          }
          title={title}
          onClose={onClose}
          className="previewPanel"
        >
          {show && (
            <div style={{ overflowX: "hidden" }}>
              <div style={{ margin: "0 -10px" }}>
                <div style={{ height: "100%" }} id="previewContainer">
                  {fetchingWidget && !previewData.error_reason && (
                    <div
                      className="flex justify-center items-center absolute inset-0"
                      style={{ height: "calc(100vh - 80px)" }}
                    >
                      <Loader center={false} />
                    </div>
                  )}
                  {previewData.error_reason && (
                    <p style={{ margin: "1rem" }} className="error">
                      There was a problem while fetching your preview solution.
                      Please try again later.
                    </p>
                  )}
                </div>
                {/* </Modal.Body> */}
              </div>
            </div>
          )}
        </PreviewPanel>
      </div>
    );
  }
);

PreviewModalNew.propTypes = {
  customer: PropTypes.object,
  botData: PropTypes.object,
  show: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
  variant: PropTypes.string,
  flags: PropTypes.object,
  editFlag: PropTypes.bool,
  botUserId: PropTypes.string,
  updateMetadata: PropTypes.func,
  sandboxPreviewMetadata: PropTypes.string,
};

PreviewModalNew.defaultProps = {
  customer: {},
  botData: {},
  flags: {},
  show: false,
  editFlag: false,
  title: "",
  variant: "",
  botUserId: "",
  sandboxPreviewMetadata: "",
  handleEdit: () => {},
  updateMetadata: () => {},
  handleClose: () => {},
};

PreviewModalNew.displayName = "PreviewModalNew";

export default PreviewModalNew;
