export default [
  {
    id: 9999,
    type: "D",
    title: "Version",
    intent: "get_version",
    linkto: 1,
    message: "Version X - Current Date and Time",
    behaviors: "",
  },
  {
    id: 99990,
    type: "D",
    title: "Error action node",
    intent: "",
    linkto: 1,
    message: "",
    behaviors: "",
  },
  {
    id: 1800,
    type: "D",
    title: "OutofScope",
    intent: "out_of_scope",
    message: `I didn't understand your message. Please try again.`,
    behaviors: "",
  },
  {
    id: 666,
    type: "D",
    title: "End Chat",
    intent: "end_chat",
    message: "",
    behaviors: "",
  },
  {
    id: 999,
    type: "D",
    title: "Agent",
    intent: "transfer_to_agent",
    message: "Transferring you to an agent.",
    behaviors: "xfer_to_agent",
  },
];
