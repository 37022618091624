import { NODE_SETTING } from "../enum";

const NodeMode = NODE_SETTING.MODE;
const NodeType = NODE_SETTING.TYPE;

const searchParent = (node, target, success, args = {}, parents = []) => {
  let flag = success;
  if (node.id === target) {
    flag = true;
  }

  (node.children || []).forEach((child) => {
    flag = flag || searchParent(child, target, flag, args, parents);
  });

  if (flag) {
    // in case of parent
    Object.keys(args).forEach((field) => {
      node[field] = args[field];
    });

    if (node.id !== "n0") {
      parents.push(node.id);
    }
  }

  return flag;
};

const searchChildren = (
  node,
  target,
  success,
  args1 = {},
  args2 = {},
  exceptSelf = false,
  children = []
) => {
  let flag = success;
  if (node.id === target) {
    flag = true;
  }

  for (let i = 0; i < (node.children || []).length; i++) {
    searchChildren(node.children[i], target, flag, args1, {}, false, children);
  }

  if (
    [
      NodeType.DECISION,
      NodeType.ACTION,
      NodeType.ORPHAN,
      NodeType.UNKNOWN,
    ].includes(node.type)
  ) {
    const args = flag ? args1 : args2;
    if (!exceptSelf || node.id !== target) {
      Object.keys(args).forEach((field) => {
        if (field === "collapsed") {
          node[field] = node.mode === NodeMode.GROUP || args[field];
        } else {
          node[field] = args[field];
        }
        node[field] = args[field];
      });
    }
    if (flag) {
      children.push(node.id);
    }
  }
};

const searchNode = (node, targets = [], args1 = {}, args2 = {}) => {
  // update
  if (targets.includes(node.id)) {
    Object.keys(args1).forEach((field) => {
      node[field] = args1[field];
    });
  } else {
    Object.keys(args2).forEach((field) => {
      node[field] = args2[field];
    });
  }

  // check if it is end.
  if (!node.children || node.children.length === 0) {
    return;
  }

  for (let i = 0; i < (node.children || []).length; i++) {
    searchNode(node.children[i], targets, args1, args2);
  }
};

const getNodeInSolution = (src, nodeId) => {
  if (src.id === nodeId) {
    return src;
  }
  let res = null;
  // check if it is end.
  if (src.children) {
    src.children.forEach((child) => {
      if (!res) {
        res = getNodeInSolution(child, nodeId);
      }
    });
  }

  return res;
};

const getTopParentNode = (node, target, success) => {
  let flag = success;
  let topParent;
  if (node.id === target) {
    flag = true;
  }

  for (let i = 0; i < (node.children || []).length; i++) {
    if (!flag) {
      const result = getTopParentNode(node.children[i], target, flag);
      flag = result.success;
      topParent = result.topParent;
    }
  }

  return {
    success: flag,
    topParent: topParent
      ? topParent
      : flag && node.root && node.id !== "n0"
      ? node.id
      : null,
  };
};

const hasNodeAsChild = (node, { id, number }, success) => {
  let flag = success;
  if (node.id === id || node.number === number) {
    flag = true;
  }

  (node.children || []).forEach((child) => {
    if (!flag) {
      flag = hasNodeAsChild(child, { id, number }, flag);
    }
  });

  return flag;
};

const getOrgChartWidth = (node) => {
  let width = 0;

  const loopNode = (node) => {
    if (!node.children || node.children.length === 0) {
      width += 1;
      return;
    }

    node.children.forEach((child) => {
      loopNode(child);
    });
  };

  loopNode(node);

  return width;
};

export {
  searchParent,
  searchChildren,
  searchNode,
  getTopParentNode,
  hasNodeAsChild,
  getOrgChartWidth,
  getNodeInSolution,
};
