export default {
  HEADERS: [
    {
      label: "Node Number",
      key: "id",
    },
    {
      label: "Node Type",
      key: "type",
    },
    {
      label: "Node Name",
      key: "title",
    },
    {
      label: "Intent",
      key: "intent",
    },
    {
      label: "Entity Type",
      key: "entityType",
    },
    {
      label: "Entity",
      key: "entity",
    },
    {
      label: "NLU Disabled?",
      key: "NLUDisabled",
    },
    {
      label: "Next Nodes",
      key: "linkto",
    },
    {
      label: "Message",
      key: "message",
    },
    {
      label: "Rich Asset Type",
      key: "richAssetType",
    },
    {
      label: "Rich Asset Content",
      key: "richAssetContent",
    },
    {
      label: "Answer Required?",
      key: "answerRequired",
    },
    {
      label: "Behaviors",
      key: "behaviors",
    },
    {
      label: "Command",
      key: "command",
    },
    {
      label: "Description",
      key: "description",
    },
    {
      label: "Output",
      key: "output",
    },
    {
      label: "Node Input",
      key: "nodeInput",
    },
    {
      label: "Parameter Input",
      key: "parameterInput",
    },
    {
      label: "Decision Variable",
      key: "decisionVariable",
    },
    {
      label: "What Next?",
      key: "whatNext",
    },
    {
      label: "Node Tags",
      key: "nodeTag",
    },
    {
      label: "Skill Tag",
      key: "skillTag",
    },
    {
      label: "Variable",
      key: "variable",
    },
    {
      label: "Platform Flag",
      key: "platformFlag",
    },
    {
      label: "Flows",
      key: "flows",
    },
  ],
};
