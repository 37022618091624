import Papa from "papaparse";
import { nodesReceived, validateNode } from "Designer/Store/actions";
import {
  DEFAULT_NODE_WIDTH,
  DEFAULT_NODE_HEIGHT,
  RICH_ASSET_TYPE_BUTTON,
  RICH_ASSET_TYPE_CAROUSEL,
  RICH_ASSET_TYPE_DATEPICKER,
  RICH_ASSET_TYPE_DOCUMENT,
  RICH_ASSET_TYPE_FILE_UPLOAD,
  RICH_ASSET_TYPE_IMAGEBUTTON,
  RICH_ASSET_TYPE_LOCATION,
  RICH_ASSET_TYPE_LOGIN_BUTTON,
  RICH_ASSET_TYPE_IMAGE,
  RICH_ASSET_TYPE_SECURE,
  RICH_ASSET_TYPE_SELECTION,
  RICH_ASSET_TYPE_LISTPICKER,
  RICH_ASSET_TYPE_TIMEPICKER,
  RICH_ASSET_TYPE_VIDEO,
  RICH_ASSET_TYPE_WEBVIEW,
  RICH_ASSET_TYPE_PERSISTENT_MENU,
} from "Designer/lib/defs";
import {
  decodeRichAssetContentButtonsOrList,
  encodeRichAssetContentButtonsOrList,
} from "Designer/Components/richContents/buttonsAndList/utils";
import {
  decodeRichAssetContentPersistentMenu,
  encodeRichAssetContentPersistentMenu,
} from "Designer/Components/richContents/persistentMenu/utils";
import {
  decodeRichAssetContentWebView,
  encodeRichAssetContentWebView,
} from "Designer/Components/richContents/webview/utils";
import { uniq } from "Designer/lib/utills";
import {
  decodeRichAssetContentCarousel,
  encodeRichAssetContentCarousel,
} from "Designer/Components/richContents/carousel/utils";
import NodeContainer from "Designer/lib/nodesContainer";
import {
  decodeRichAssetContentDocument,
  encodeRichAssetContentDocument,
} from "Designer/Components/richContents/document/utils";
import {
  decodeRichAssetContentRichMedia,
  encodeRichAssetContentRichMedia,
} from "Designer/Components/richContents/richMedia/utils";
import {
  decodeRichAssetContentDateTime,
  encodeRichAssetContentDateTime,
} from "Designer/Components/richContents/dataTimePickers/utils";
import {
  decodeRichAssetContentSecure,
  encodeRichAssetContentSecure,
} from "Designer/Components/richContents/secure/utils";
import {
  decodeRichAssetContentAuthentication,
  encodeRichAssetContentAuthentication,
} from "Designer/Components/richContents/authentication/utils";
import {
  decodeRichAssetContentFileUpload,
  encodeRichAssetContentFileUpload,
} from "Designer/Components/richContents/fileUpload/utils";
import {
  decodeRichAssetContentLocation,
  encodeRichAssetContentLocation,
} from "Designer/Components/richContents/location/utils";
import {
  decodeActionNodeInput,
  decodeActionWhatNext,
  encodeActionNodeInput,
  encodeActionWhatNext,
} from "Designer/Components/richContents/action/utils";
import { MAX_TREE_DEPTH, NODE_STATE, PRESERVE_NODE_NUMBERS } from "./defs";

// ' is prefixed for @ + - = | % in CSV to escape malicious functions in MS Excel

// eslint-disable-next-line no-useless-escape
const UNESCAPE_CHAR_PATTERN = /("\s*|\n|,\s*)'(?=\s*[@\+\-=\|%])/gm;
// eslint-disable-next-line no-useless-escape
const ESCAPE_CHAR_PATTERN = /("\s*|\n|,\s*)([@\+\-=\|%])/gm;

function decodeRichAssetContent(data, meta) {
  switch (data.rich_asset_type) {
    case RICH_ASSET_TYPE_BUTTON:
    case RICH_ASSET_TYPE_IMAGEBUTTON:
    case RICH_ASSET_TYPE_SELECTION:
    case RICH_ASSET_TYPE_LISTPICKER:
      return decodeRichAssetContentButtonsOrList(
        data.rich_asset_content,
        data.rich_asset_type
      );
    case RICH_ASSET_TYPE_PERSISTENT_MENU:
      return decodeRichAssetContentPersistentMenu(
        data.rich_asset_content,
        data.rich_asset_type
      );
    case RICH_ASSET_TYPE_WEBVIEW:
      return decodeRichAssetContentWebView(data, meta);
    case RICH_ASSET_TYPE_CAROUSEL:
      return decodeRichAssetContentCarousel(data.rich_asset_content);
    case RICH_ASSET_TYPE_DOCUMENT:
      return decodeRichAssetContentDocument(data.rich_asset_content);
    case RICH_ASSET_TYPE_IMAGE:
    case RICH_ASSET_TYPE_VIDEO:
      return decodeRichAssetContentRichMedia(
        data.rich_asset_content,
        data.rich_asset_type
      );
    case RICH_ASSET_TYPE_DATEPICKER:
    case RICH_ASSET_TYPE_TIMEPICKER:
      return decodeRichAssetContentDateTime(data.rich_asset_content);
    case RICH_ASSET_TYPE_SECURE:
      return decodeRichAssetContentSecure(data.rich_asset_content);
    case RICH_ASSET_TYPE_LOGIN_BUTTON:
      return decodeRichAssetContentAuthentication(data.rich_asset_content);
    case RICH_ASSET_TYPE_FILE_UPLOAD:
      return decodeRichAssetContentFileUpload(data.rich_asset_content);
    case RICH_ASSET_TYPE_LOCATION:
      return decodeRichAssetContentLocation(data.rich_asset_content);

    default:
      return data.rich_asset_content;
  }
}

function encodeRichAssetContent(data, allNodes) {
  switch (data.rich_asset_type) {
    case RICH_ASSET_TYPE_BUTTON:
    case RICH_ASSET_TYPE_IMAGEBUTTON:
    case RICH_ASSET_TYPE_SELECTION:
    case RICH_ASSET_TYPE_LISTPICKER:
      return encodeRichAssetContentButtonsOrList(
        data.rich_asset_content,
        data.rich_asset_type
      );
    case RICH_ASSET_TYPE_PERSISTENT_MENU:
      return encodeRichAssetContentPersistentMenu(
        data.rich_asset_content,
        data.rich_asset_type
      );
    case RICH_ASSET_TYPE_WEBVIEW:
      return encodeRichAssetContentWebView(data);
    case RICH_ASSET_TYPE_CAROUSEL:
      return encodeRichAssetContentCarousel(data.rich_asset_content);
    case RICH_ASSET_TYPE_DOCUMENT:
      return encodeRichAssetContentDocument(data.rich_asset_content);
    case RICH_ASSET_TYPE_IMAGE:
    case RICH_ASSET_TYPE_VIDEO:
      return encodeRichAssetContentRichMedia(
        data.rich_asset_content,
        data.rich_asset_type
      );
    case RICH_ASSET_TYPE_DATEPICKER:
    case RICH_ASSET_TYPE_TIMEPICKER:
      return encodeRichAssetContentDateTime(data.rich_asset_content);
    case RICH_ASSET_TYPE_SECURE:
      return encodeRichAssetContentSecure(data.rich_asset_content);
    case RICH_ASSET_TYPE_LOGIN_BUTTON:
      return encodeRichAssetContentAuthentication(data.rich_asset_content);
    case RICH_ASSET_TYPE_FILE_UPLOAD:
      return encodeRichAssetContentFileUpload(data, allNodes);
    case RICH_ASSET_TYPE_LOCATION:
      return encodeRichAssetContentLocation(data.rich_asset_content);

    default:
      return data.rich_asset_content;
  }
}

function extractNextNodes(data) {
  try {
    if (data.type === "D") {
      if (data.rich_asset_type === RICH_ASSET_TYPE_SELECTION) {
        return data.rich_asset_content.isDynamic
          ? data.next_nodes
          : uniq(
              data.rich_asset_content.options
                .map((i) => i.next_node)
                .filter((n) => n)
            );
      }
      switch (data.rich_asset_type) {
        case RICH_ASSET_TYPE_BUTTON:
        case RICH_ASSET_TYPE_IMAGEBUTTON:
        case RICH_ASSET_TYPE_LISTPICKER:
          return uniq(
            data.rich_asset_content.options
              .map((i) => i.next_node)
              .filter((n) => n)
          );
        case RICH_ASSET_TYPE_CAROUSEL:
          return uniq(
            data.rich_asset_content.map((i) => Number(i.dest)).filter((n) => n)
          );
        default:
          return data.next_nodes;
      }
    } else if (Array.isArray(data.what_next)) {
      return uniq(data.what_next.map((i) => i.next_node).filter((n) => n));
    }
  } catch (e) {
    console.error(e);
    console.error(data);
  }
  return [];
}

function setNextNodesInCSV(data) {
  if (
    data.type === "D" &&
    (data.rich_asset_type === RICH_ASSET_TYPE_BUTTON ||
      data.rich_asset_type === RICH_ASSET_TYPE_IMAGEBUTTON ||
      data.rich_asset_type === RICH_ASSET_TYPE_LISTPICKER ||
      data.rich_asset_type === RICH_ASSET_TYPE_CAROUSEL)
  )
    return "";
  if (data.type === "D" && data.rich_asset_type === RICH_ASSET_TYPE_SELECTION) {
    return data.rich_asset_content.isDynamic ? data.next_nodes : "";
  }
  if (data.type === "A") return "";
  return data.next_nodes;
}

export function hasLocalBot(customerId, botId) {
  const theBot = window.localStorage.getItem(`bot:${customerId}:${botId}`);
  if (theBot) {
    try {
      const data = JSON.parse(theBot);
      if (data && data.nodes && Array.isArray(data.nodes) && data.nodes.length)
        return true;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}

export function importCsvFromStream(stream, fromLocalImport) {
  return (dispatch /* , getState */) => {
    const config = {
      header: true,
      dynamicTyping: true,
      delimiter: ",",
      complete(results, file) {
        console.log("Parsing complete:", results, file);
        const newNodes = getPredefinedNodes(); /// if they already exist on the csv than they will be overwritten
        let csvHasOutOfScopeNode = false;
        let maxNodeNumber = 0;
        if (results && results.errors.length === 0) {
          results.data.forEach((row) => {
            if (row["Node Number"]) {
              const metaStr = row["Design Information"];
              let meta = {
                x: 0,
                y: newNodes.length * 300,
                width: DEFAULT_NODE_WIDTH,
                height: DEFAULT_NODE_HEIGHT,
                edges: [],
              };
              if (metaStr) {
                const metaObj = JSON.parse(metaStr);
                if (
                  typeof metaObj === "object" &&
                  ((metaObj.x !== undefined && metaObj.y !== undefined) ||
                    metaObj.readOnly)
                ) {
                  meta = {
                    ...metaObj,
                    width: DEFAULT_NODE_WIDTH,
                    height: DEFAULT_NODE_HEIGHT,
                  }; //TODO remove at aug2019
                }
              }

              const data = {
                number: row["Node Number"],
                type: meta.isGroup ? "G" : row["Node Type"],
                name: String(row["Node Name"] || ""),
                intent: row.Intent,
                entity_type: row["Entity Type"],
                entity: row.Entity,
                nlu_disabled: row["NLU Disabled?"],
                next_nodes: [],
                message: String(row.Message || ""),
                rich_asset_type: row["Rich Asset Type"],
                rich_asset_content: row["Rich Asset Content"],
                answer_required: row["Answer Required?"],
                behaviors: row.Behaviors,
                command: row.Command,
                description: String(row.Description || ""),
                output: row.Output,
                node_input: row["Node Input"],
                parameter_input: row["Parameter Input"] || "",
                decision_variable: row["Decision Variable"],
                what_next: row["What Next?"],
                node_tags: row["Node Tags"],
                skill_tag: row["Skill Tag"],
                variable: row.Variable,
                platform_flag: row["Platform Flag"],
              };

              if (data.type === "D") {
                if (data.rich_asset_type === "png") {
                  // convert old type to new type
                  data.rich_asset_type = RICH_ASSET_TYPE_IMAGE;
                }
                data.rich_asset_content = decodeRichAssetContent(data, meta);

                if (row["Next Nodes"]) {
                  if (typeof row["Next Nodes"] === "number")
                    data.next_nodes.push(row["Next Nodes"]);
                  else if (typeof row["Next Nodes"] === "string")
                    data.next_nodes = uniq(
                      row["Next Nodes"].split(",").map((s) => parseInt(s, 10))
                    );
                  else data.next_nodes = [];
                }
              } else {
                data.what_next = decodeActionWhatNext(data.what_next);
                data.node_input = decodeActionNodeInput(data.node_input);
              }

              if (row["NLP Keywords"]) {
                meta.NLPKeywords = row["NLP Keywords"];
              }

              if (data.number === 1) {
                meta.readOnly = true;
              }

              data.next_nodes = extractNextNodes(data);

              data.variableCheckBox = !!data.variable;

              if (data.answer_required) {
                if (
                  !meta.ADUR &&
                  (!data.rich_asset_type ||
                    data.rich_asset_type === RICH_ASSET_TYPE_SECURE ||
                    data.rich_asset_type === RICH_ASSET_TYPE_DATEPICKER ||
                    data.rich_asset_type === RICH_ASSET_TYPE_TIMEPICKER ||
                    data.rich_asset_type === RICH_ASSET_TYPE_WEBVIEW ||
                    data.rich_asset_type === RICH_ASSET_TYPE_LOCATION)
                ) {
                  meta.ADUR = true;
                  data.nlu_disabled = 1;
                }
              }

              meta.state = NODE_STATE.NODE_STATE_NOT_CONNECTED;

              if (data.number >= 0 && data.intent === "out_of_scope") {
                csvHasOutOfScopeNode = true;
                meta.groupNumber = PRESERVE_NODE_NUMBERS.HiddenGroup;
              }

              if (data.number < 0) {
                meta.groupNumber = PRESERVE_NODE_NUMBERS.HiddenGroup;
              }

              if (meta.isGroup) {
                meta.childNodes = [];
              }

              if (data.number > maxNodeNumber) {
                maxNodeNumber = data.number;
              }
              newNodes.push({ data, meta });
            }
          });

          if (!csvHasOutOfScopeNode) {
            newNodes.push(getOutOfScopeNode(maxNodeNumber + 1));
          }

          newNodes.push(getRootGroupNode());

          const tempNodeContainer = new NodeContainer();
          tempNodeContainer.insertNodes(newNodes);

          newNodes.forEach((node) => {
            //set node errors
            validateNode(node, null, null, tempNodeContainer, false);

            if (node.meta.groupNumber === undefined) {
              node.meta.groupNumber = PRESERVE_NODE_NUMBERS.RootGroup;
            }

            //add node to parents childNodes
            const groupNumber =
              node.meta.groupNumber || PRESERVE_NODE_NUMBERS.RootGroup;

            if (node.meta.groupNumber !== PRESERVE_NODE_NUMBERS.HiddenGroup) {
              let groupNode = tempNodeContainer.getNodeRead(groupNumber);

              if (!groupNode) {
                //cant find group? set to root group
                node.meta.groupNumber = PRESERVE_NODE_NUMBERS.RootGroup;
                groupNode = tempNodeContainer.getNodeRead(
                  PRESERVE_NODE_NUMBERS.RootGroup
                );
              }

              groupNode.meta.childNodes.push(node.data.number);
            }
          });

          let allNodes = new NodeContainer();
          allNodes.insertNodes(newNodes);
          return dispatch(
            nodesReceived(
              allNodes,
              true,
              fromLocalImport,
              PRESERVE_NODE_NUMBERS.RootGroup,
              true
            )
          );
        }
      },
      skipEmptyLines: true,
      error: function () {
        console.error(arguments);
      },
    };
    // remove any ' in the beginning of a field value
    const unescapedStreams = stream.replace(UNESCAPE_CHAR_PATTERN, ($0) =>
      $0.replace("'", "")
    );
    return Papa.parse(unescapedStreams, config);
  };
}

export function importCsvFromFile(inputElement) {
  return importCsvFromStream(inputElement, true);
}

function fillMetaWithRichContentData(node) {
  switch (node.data.rich_asset_type) {
    case RICH_ASSET_TYPE_WEBVIEW:
      node.meta.rich_content = { ogs: node.data.rich_asset_content.ogs };
      break;
    default:
      break;
  }
}

export function getPredefinedNodes() {
  return [
    {
      data: {
        number: PRESERVE_NODE_NUMBERS.HyperspaceAgent,
        type: "D",
        name: "Agent",
        next_nodes: [1],
      },
      meta: {
        readOnly: true,
        groupNumber: PRESERVE_NODE_NUMBERS.HiddenGroup,
      },
    },
    {
      data: {
        number: PRESERVE_NODE_NUMBERS.HyperspaceStartOver,
        type: "D",
        name: "Start Over",
        next_nodes: [1],
      },
      meta: {
        readOnly: true,
        groupNumber: PRESERVE_NODE_NUMBERS.HiddenGroup,
      },
    },
    {
      data: {
        number: PRESERVE_NODE_NUMBERS.HyperspaceEnd,
        type: "D",
        name: "End Conversation",
        next_nodes: [1],
      },
      meta: {
        readOnly: true,
        groupNumber: PRESERVE_NODE_NUMBERS.HiddenGroup,
      },
    },
  ];
}

export const getDefaultNode = () => ({
  data: {
    number: PRESERVE_NODE_NUMBERS.StartNode,
    type: "D",
    name: "Start Node",
    next_nodes: [],
    what_next: [],
    variableCheckBox: false,
    message:
      "I can help you get tickets for an event, directions and answer some common questions.\n\nWhat would you like to do first?",
  },
  meta: {
    x: 350,
    y: 50,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
    edges: [],
    readOnly: true,
    groupNumber: PRESERVE_NODE_NUMBERS.RootGroup,
  },
});

export const getRootGroupNode = () => ({
  data: {
    number: PRESERVE_NODE_NUMBERS.RootGroup,
    type: "D",
    name: "",
    next_nodes: [],
  },
  meta: {
    childNodes: [],
    groupNumber: PRESERVE_NODE_NUMBERS.HiddenGroup,
  },
});

export const getOutOfScopeNode = (newNodeNumber) => ({
  data: {
    number: newNodeNumber,
    type: "D",
    name: "Out Of Scope",
    next_nodes: [1],
    intent: "out_of_scope",
    message: "Sorry, your human language isn't clear to me.",
  },
  meta: {
    readOnly: true,
    groupNumber: PRESERVE_NODE_NUMBERS.HiddenGroup,
  },
});

function convertNode2CsvLine(node, nodes) {
  const simpleMeta = ({
    x,
    y,
    width,
    height,
    NLPKeywords,
    isGroup,
    groupNumber,
    hideEdges,
    readOnly,
  }) => ({
    x,
    y,
    width,
    height,
    NLPKeywords,
    isGroup,
    groupNumber,
    hideEdges,
    readOnly,
  });

  return {
    "Node Number": node.data.number,
    "Node Type": node.data.type === "A" ? "A" : "D",
    "Node Name": node.data.name,
    Intent: node.data.intent,
    "Entity Type": node.data.entity_type,
    Entity: node.data.entity,
    "NLU Disabled?": node.data.nlu_disabled,
    "Next Nodes": setNextNodesInCSV(node.data),
    Message: node.data.message,
    "Rich Asset Type": node.data.rich_asset_type,
    "Rich Asset Content": encodeRichAssetContent(node.data, nodes),
    "Answer Required?": node.data.answer_required,
    Behaviors: node.data.behaviors,
    Command: node.data.command,
    Description: node.data.description,
    Output: node.data.output,
    "Node Input":
      node.data.type === "A" ? encodeActionNodeInput(node.data.node_input) : "",
    "Parameter Input": node.data.parameter_input,
    "Decision Variable":
      node.data.type === "A" ||
      (node.data.answer_required && node.data.variableCheckBox)
        ? node.data.decision_variable
        : "",
    "What Next?": encodeActionWhatNext(node.data.what_next),
    "Node Tags": node.data.node_tags,
    "Skill Tag": node.data.skill_tag,
    Variable: node.data.variable,
    "Platform Flag": node.data.platform_flag,
    "Design Information": node.meta && JSON.stringify(simpleMeta(node.meta)),
  };
}

export function getNodesReadyForCsv(nodes) {
  const data = nodes
    .mapNodes((node) => {
      delete node.meta.isNew;
      fillMetaWithRichContentData(node);
      return convertNode2CsvLine(node, nodes);
    })
    .filter((node) => node["Node Number"] !== PRESERVE_NODE_NUMBERS.RootGroup);

  return data;
}

export function exportToCSV() {
  return (dispatch, getState) => {
    const state = getState();
    const { nodes } = state.designer.present;
    const nodsReadyToCsv = getNodesReadyForCsv(nodes);
    const csv = Papa.unparse(nodsReadyToCsv);
    // add ' back to where it was. They were removed when constructing the graph (importCsvFromStream function)
    const updatesCsvData = csv.replace(
      ESCAPE_CHAR_PATTERN,
      ($0, $1, $2) => `${$1}'${$2}`
    );
    const binaryData = [];
    binaryData.push(updatesCsvData);
    const theBlob = new Blob(binaryData, { type: "text/plain" });

    const a = document.createElement("a");
    const url = window.URL.createObjectURL(theBlob);
    a.href = url;
    a.download = "Design Studio Export.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
}

export function exportToXgml(nodes) {
  const data = nodes.mapNodes((node) => {
    delete node.meta.isNew;

    return `
            <section name="node">
			<attribute key="id" type="int">${node.data.number}</attribute>
			<attribute key="label" type="String">${node.data.name.replace(
        /[^0-9a-z]/gi,
        ""
      )}</attribute>
			<section name="graphics">
				<attribute key="x" type="double">${node.meta.x}</attribute>
				<attribute key="y" type="double">${node.meta.y}</attribute>
				<attribute key="w" type="double">${node.meta.width}</attribute>
				<attribute key="h" type="double">${node.meta.height}</attribute>
				<attribute key="type" type="String">rectangle</attribute>
				<attribute key="raisedBorder" type="boolean">false</attribute>
				<attribute key="fill" type="String">${
          node.data.type === "D" ? "#d4d4d4" : "#F8DDA8"
        }</attribute>
				<attribute key="outline" type="String">#000000</attribute>
			</section>
			<section name="LabelGraphics">
				<attribute key="text" type="String">${node.data.name}</attribute>
				<attribute key="fontSize" type="int">12</attribute>
				<attribute key="fontName" type="String">Dialog</attribute>
				<attribute key="model"/>
			</section>
		</section>
            `;
  });
  let edges = "";

  nodes.mapNodes((node) =>
    node.data.next_nodes.forEach((nd) => {
      edges += `
            <section name="edge">
			<attribute key="source" type="int">${node.data.number}</attribute>
			<attribute key="target" type="int">${nd}</attribute>
			<section name="graphics">
				<attribute key="fill" type="String">#000000</attribute>
				<attribute key="targetArrow" type="String">standard</attribute>
			</section>
		</section>
            `;
    })
  );

  console.log(data + edges);
}

export function getDescenders(node, allNodes, outDescenders, level = 0) {
  if (node && level < MAX_TREE_DEPTH)
    if (node.data.type === "G") {
      const children = node.meta.childNodes.map((nodeNumber) =>
        allNodes.getNodeRead(nodeNumber)
      );
      children.forEach((childNode) =>
        getDescenders(childNode, allNodes, outDescenders, ++level)
      );
    } else {
      outDescenders.push(node);
    }
}

export function getViewableAccessor(
  node,
  allNodes,
  selectedGroup,
  stopOnHiddenEdges = false
) {
  let targetNode = node;
  for (let dep = 0; dep < MAX_TREE_DEPTH; ++dep) {
    if (targetNode) {
      if (stopOnHiddenEdges && targetNode.meta.hideEdges) {
        return false;
      }
      if (targetNode.meta.groupNumber === selectedGroup) {
        return targetNode;
      }

      targetNode = allNodes.getNodeRead(targetNode.meta.groupNumber);
    } else {
      return null;
    }
  }
}

export function doesSetContainsReadOnlyNodes(allNodes, set) {
  return set.some((nodeNumber) => {
    const node = allNodes.getNodeRead(nodeNumber);
    if (node) {
      return node.meta.readOnly;
    }
    return false;
  });
}
