/* eslint-disable no-useless-escape */
import Papa from "papaparse";

const analyzeCSV = async (file) => {
  if (!file) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const config = {
      header: true,
      dynamicTyping: true,
      delimiter: ",",
      complete(results) {
        const csv = addAdditionalData(results.data);
        resolve(csv);
      },
      skipEmptyLines: true,
      error: function () {
        console.error(arguments);
        reject(arguments);
      },
    };

    Papa.parse(file, config);
  });
};

const addAdditionalData = (data) => {
  const editedCsv = [];

  data.forEach((row) => {
    // Constants
    const currentNodeNumber = Number(row["Node Number"]);
    const nextNode = row["Next Nodes"];
    const whatNext = row["What Next?"];
    const richAssetContent = row["Rich Asset Content"];

    // Variables
    let outgoingCount = 0;
    let incomingCount = 0;
    let parentNodes = [];
    let editedRow = {
      ...row,
    };

    if (nextNode) {
      outgoingCount++;
    }

    if (whatNext) {
      const parsedWhatNext = whatNext.split("|");
      outgoingCount += parsedWhatNext.length;
    }

    if (richAssetContent) {
      // Check for nodes mentioned in the "dest" option.
      if (richAssetContent.includes("dest")) {
        const destRegex = /("dest":.*)/g;
        const matchedAssets = richAssetContent.match(destRegex);
        if (matchedAssets) {
          outgoingCount += matchedAssets.length;
        }
      }

      // Check for node with the ~x syntax.
      const numberWithTildaMatch = /(\~\d+)/g;
      const matchedNumbers = richAssetContent.match(numberWithTildaMatch);

      if (matchedNumbers) {
        outgoingCount += matchedNumbers.length;
      }
    }

    editedRow["Outgoing"] = outgoingCount;

    data.forEach((innerRow) => {
      const innerNextNode = innerRow["Next Nodes"];
      const innerWhatNext = innerRow["What Next?"];
      const innerRichAssetContent = innerRow["Rich Asset Content"];

      if (innerNextNode) {
        if (Number(innerNextNode) === currentNodeNumber) {
          parentNodes.push({
            label: innerRow["Node Name"],
            number: innerRow["Node Number"],
            type: innerRow["Node Type"],
          });
          incomingCount++;
        }
      }

      if (innerWhatNext) {
        const parsedWhatNext = innerWhatNext.split("|");

        parsedWhatNext &&
          parsedWhatNext.forEach((node) => {
            const rawNode = Number(node.slice(node.indexOf("~") + 1));
            if (rawNode === currentNodeNumber) {
              incomingCount++;
              parentNodes = [
                ...parentNodes.filter(
                  (item) => item.number !== innerRow["Node Number"]
                ),
                {
                  label: innerRow["Node Name"],
                  number: innerRow["Node Number"],
                  type: innerRow["Node Type"],
                },
              ];
            }
          });
      }

      if (innerRichAssetContent) {
        // Check for nodes mentioned in the "dest" option.
        if (innerRichAssetContent.includes("dest")) {
          const destRegex = /("dest":.*)/g;
          const matchedAssets = innerRichAssetContent.match(destRegex);

          matchedAssets &&
            matchedAssets.forEach((asset) => {
              const parsedNumber = Number(asset.match(/\d+/g)[0]);
              if (parsedNumber === currentNodeNumber) {
                incomingCount++;
                parentNodes = [
                  ...parentNodes.filter(
                    (item) => item.number !== innerRow["Node Number"]
                  ),
                  {
                    label: innerRow["Node Name"],
                    number: innerRow["Node Number"],
                    type: innerRow["Node Type"],
                  },
                ];
              }
            });
        }

        // Check for node with the ~x syntax.
        const numberWithTildaMatch = /(\~\d+)/g;
        const matchedNumbers =
          innerRichAssetContent.match(numberWithTildaMatch);

        if (matchedNumbers) {
          matchedNumbers.forEach((number) => {
            const parsedNumber = Number(number.slice(1));
            if (parsedNumber === currentNodeNumber) {
              incomingCount++;
              parentNodes = [
                ...parentNodes.filter(
                  (item) => item.number !== innerRow["Node Number"]
                ),
                {
                  label: innerRow["Node Name"],
                  number: innerRow["Node Number"],
                  type: innerRow["Node Type"],
                },
              ];
            }
          });
        }
      }
    });

    editedRow["Incoming"] = incomingCount;
    editedRow["Parents"] = parentNodes;
    editedCsv.push(editedRow);
  });

  return editedCsv;
};

export { analyzeCSV, addAdditionalData };
