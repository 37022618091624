import {
  SET_NODE_DETAILS_OPEN,
  OPEN_IMPORT_CSV_MODAL,
  SET_LEFT_NODE_PANEL_OPEN,
} from "./actionTypes";

const getInitialState = () => ({
  nodeDetailsOpened: {
    Dialog: false,
    System: false,
  },
  openImportCSVModal: false,
  openNodePanel: false,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_NODE_DETAILS_OPEN:
      return Object.assign({}, state, {
        nodeDetailsOpened: {
          ...state.nodeDetailsOpened,
          [action.data.tab]: action.data.open,
        },
      });
    case OPEN_IMPORT_CSV_MODAL:
      return Object.assign({}, state, {
        openImportCSVModal: action.data.flag,
      });
    case SET_LEFT_NODE_PANEL_OPEN:
      return Object.assign({}, state, {
        openNodePanel: action.data.open,
      });
    default:
      return state;
  }
};
