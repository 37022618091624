import { DEFAULT_NODE_HEIGHT, DEFAULT_NODE_WIDTH } from "./defs";
import { Map } from "immutable";

export default class NodeContainer {
  constructor(dataRef) {
    if (dataRef && Map.isMap(dataRef)) {
      this._dataRef = dataRef;
    } else {
      this._dataRef = Map({
        nodes: Map(),
        maxHeight: 0,
        maxWidth: 0,
        maxNodeNumber: 0,
        globalVariablesData: [],
      });
    }
  }

  getData() {
    return this._dataRef;
  }

  updateNode(node, field) {
    if (node) {
      this._dataRef = this._dataRef.setIn(["nodes", node.data.number], node);
      this.extractGlobalVariables(field);
    }
  }

  updateNodeLocation(number, x, y) {
    const node = this._dataRef.getIn(["nodes", number]);
    if (node) {
      this._dataRef = this._dataRef.setIn(["nodes", number], {
        ...node,
        meta: { ...node.meta, x, y },
      });
    }
    this.resetBoundaries();
  }

  insertNodes(arrayOfNodes) {
    let maxNodeNumber = 0;

    let nodes = Map();
    arrayOfNodes.forEach((n) => {
      nodes = nodes.set(n.data.number, n);
      if (n.data.number > maxNodeNumber) maxNodeNumber = n.data.number;
    });

    this._dataRef = this._dataRef.withMutations((map) =>
      map.set("nodes", Map(nodes)).set("maxNodeNumber", maxNodeNumber)
    );
    this.extractGlobalVariables("all");
    this.resetBoundaries();
  }

  insertNode(node) {
    this._dataRef = this._dataRef.setIn(["nodes", node.data.number], node);
    this.resetBoundaries();
    if (node.data.number > this._dataRef.get("maxNodeNumber")) {
      this._dataRef = this._dataRef.set("maxNodeNumber", node.data.number);
    }
    this.extractGlobalVariables("all");
    return this;
  }

  deleteNode(number) {
    const deletedNode = this._dataRef.getIn(["nodes", number]);
    if (deletedNode) {
      this._dataRef = this._dataRef.deleteIn(["nodes", number]);
      this.resetBoundaries();
      this.extractGlobalVariables("all");
    }
    return deletedNode;
  }

  extractGlobalVariables(field) {
    const needChange =
      field === "all" ||
      field === "variable" ||
      field === "output" ||
      field === "variableCheckBox";

    if (needChange) {
      const variablesSet = new Set();
      this.forEachNode((node) => {
        if (
          node.data.type === "D" &&
          node.data.variable &&
          node.data.variableCheckBox
        ) {
          const variables = node.data.variable.split(",");
          variables.forEach((v) => {
            variablesSet.add(v.trim());
          });
        }
        if (node.data.type === "A" && node.data.output) {
          const variables = node.data.output.split(",");
          variables.forEach((v) => {
            variablesSet.add(v.trim());
          });
        }
      });

      const sortedArray = Array.from(variablesSet);
      sortedArray.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );

      // check if a change is needed
      const globalVariablesData = this._dataRef.get("globalVariablesData");
      const isDifferent = sortedArray.some(
        (v, index) =>
          !globalVariablesData[index] || v !== globalVariablesData[index].id
      );

      if (isDifferent) {
        const toUpdate = sortedArray.map((v) => ({ id: v, display: v }));
        this._dataRef = this._dataRef.set("globalVariablesData", toUpdate);
      }
    }
  }

  forEachNode(callback) {
    this._dataRef.get("nodes").forEach((node) => {
      callback(node);
    });
  }

  mapNodes(callback) {
    return Array.from(this._dataRef.get("nodes").map(callback).values());
  }

  filterNodes(filterFunction) {
    return Array.from(
      this._dataRef.get("nodes").filter(filterFunction).values()
    );
  }

  someNodes(filterFunction) {
    return this._dataRef.get("nodes").some(filterFunction);
  }

  sortNodes(comparator) {
    return this._dataRef.get("nodes").sort(comparator);
  }

  getNodeRead(number) {
    return this._dataRef.getIn(["nodes", number]);
  }

  getNodeClone(number) {
    const node = this._dataRef.getIn(["nodes", number]);
    if (node) {
      return {
        data: { ...node.data },
        errors: { ...node.errors },
        meta: {
          ...node.meta,
          childNodes: node.meta.childNodes
            ? [...node.meta.childNodes]
            : undefined,
        },
        touched: { ...node.touched },
      };
    }
  }

  getNewNodeNumber() {
    return this._dataRef.get("maxNodeNumber") + 1;
  }

  resetBoundaries() {
    let maxHeight = 0;
    let maxWidth = 0;
    this.forEachNode((node) => {
      if (node.meta.x > maxWidth) maxWidth = node.meta.x;

      if (node.meta.y > maxHeight) maxHeight = node.meta.y;
    });

    maxHeight += 2 * DEFAULT_NODE_HEIGHT;
    maxWidth += 2 * DEFAULT_NODE_WIDTH;

    maxHeight = Math.max(maxHeight, 6000);
    maxWidth = Math.max(maxWidth, 1200);

    this._dataRef = this._dataRef.withMutations((map) =>
      map.set("maxHeight", maxHeight).set("maxWidth", maxWidth)
    );
  }

  get Height() {
    return this._dataRef.get("maxHeight");
  }

  get Width() {
    return this._dataRef.get("maxWidth");
  }

  get size() {
    return this._dataRef.get("nodes").count();
  }
}
