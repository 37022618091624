import { validatorHTTPS } from "Designer/lib/validators";
import botManagerAPI from "../../../../bot-manager-api";

export function decodeRichAssetContentDocument(rich_asset_content) {
  let url = "";
  if (rich_asset_content) {
    const splits = rich_asset_content.split(",");
    if (Array.isArray(splits) && splits.length === 2) {
      url = splits[0];
    }
  }
  return { url, firstTimeFetch: false };
}

export function encodeRichAssetContentDocument(richAssetContent) {
  return richAssetContent
    ? `${richAssetContent.url},${richAssetContent.url}`
    : null;
}

export function validateRichAssetContentDocument(
  richAssetContent,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  const errors = {};
  let foundError = false;
  if (
    richAssetContent &&
    (!validateOnlyTouched ||
      (validateOnlyTouched &&
        touched_rich_asset_content &&
        touched_rich_asset_content.url))
  ) {
    const urlError = validatorHTTPS(richAssetContent.url);
    if (urlError) {
      errors.url = urlError;
      foundError = true;
    }
  }
  return foundError ? errors : undefined;
}

export async function getPreview(url, embedContent, onChange) {
  if (
    url &&
    (url !== embedContent.url ||
      (!embedContent.wasFetched && !embedContent.isFetching))
  ) {
    let newRichContent = Object.assign({}, embedContent, {
      url,
      isFetching: true,
      wasFetched: false,
    });
    onChange(newRichContent);

    const blob = await botManagerAPI.getPdfPreview(encodeURI(url));
    if (blob.type === "image/png") {
      const src = window.URL.createObjectURL(blob);
      newRichContent = Object.assign({}, embedContent, {
        url,
        img_src: src,
        getImageFailed: false,
        isFetching: false,
        wasFetched: true,
      });
    } else {
      newRichContent = Object.assign({}, embedContent, {
        url,
        img_src: null,
        getImageFailed: true,
        isFetching: false,
        wasFetched: true,
      });
    }
    onChange(newRichContent);
  }
}
