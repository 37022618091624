import React from "react";
import PropTypes from "prop-types";
import { Header, constants } from "unified-nav";
import { userLogout } from "bot-user-session";
import { connect } from "react-redux";

const { products } = constants;

class Component extends React.Component {
  render() {
    const { userLogout, showProfile } = this.props;

    return (
      <Header
        showProfile={showProfile}
        product={products.BOT_MANAGER}
        onSignOut={userLogout}
      />
    );
  }
}

Component.propTypes = {
  userLogout: PropTypes.func,
  showProfile: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  userLogout() {
    dispatch(userLogout());
  },
});

export default connect(null, mapDispatchToProps)(Component);
