export function getError(
  errors,
  field,
  index = undefined,
  subField = undefined,
  checkEmptyObject = false
) {
  if (!errors) return undefined;
  if (!errors[field]) return undefined;

  if (index === undefined && subField === undefined) {
    return errors[field];
  }

  if (index === undefined && subField !== undefined) {
    return errors[field][subField];
  }

  //if (!Array.isArray(errors[field])) return undefined;

  if (subField === undefined) {
    if (checkEmptyObject) return !!Object.keys(errors[field][index]).length;
    return errors[field][index];
  }

  return errors[field][index][subField];
}

const urlPattern = new RegExp(
  "^(https:\\/\\/)" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?" + // port
    "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
    "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
); // fragment locator

export function validatorHTTPS(value) {
  if (!value) return "A valid URL is required";
  if (!urlPattern.test(value)) {
    return "A valid URL is required";
  }

  return undefined;
}

export function validatorNotEmptyMsg(value, message) {
  return value ? undefined : message;
}

export function validatorNotEmpty(value) {
  return !!value;
}
