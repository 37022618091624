/* eslint-disable no-empty */
/* eslint  no-undef: off */
/* eslint  no-unused-vars: off */
// import mxClient from 'script-loader!mxgraph/javascript/mxClient';
import { getViewableAccessor } from "../botUtills";

export default class DG {
  static drawGraph(allNodes, selectedGroup, groupLevelNodes) {
    let startTime;
    let endTime;
    startTime = new Date();
    const container = document.createElement("div");

    // Disables the built-in context menu

    mxEvent.disableContextMenu(container);

    // Creates the graph inside the given container
    const graph = new mxGraph(container);

    // Gets the default parent for inserting new cells. This
    // is normally the first child of the root (ie. layer 0).
    const parent = graph.getDefaultParent();

    const selectionCells = [];
    // Adds cells to the model in a single step
    graph.getModel().beginUpdate();
    try {
      groupLevelNodes.forEach((nodeNumber) => {
        const node = allNodes.getNodeRead(nodeNumber);
        node.graphVertex = graph.insertVertex(
          parent,
          node.data.number,
          node.data.name,
          node.meta.x,
          node.meta.y,
          node.meta.width,
          node.meta.height,
          "fillColor=green"
        );
        selectionCells.push(node.graphVertex);
      });

      groupLevelNodes.forEach((nodeNumber) => {
        const node = allNodes.getNodeRead(nodeNumber);
        if (node.data.next_nodes) {
          node.meta.edges = [];
          node.data.next_nodes.forEach((nn) => {
            if (nn !== node.data.number) {
              const nextNode = allNodes.getNodeRead(nn);
              if (nextNode) {
                const targetNode = getViewableAccessor(
                  nextNode,
                  allNodes,
                  selectedGroup,
                  true
                );
                if (targetNode && targetNode.graphVertex) {
                  graph.insertEdge(
                    parent,
                    null,
                    `${node.data.number}_${targetNode.data.number}`,
                    node.graphVertex,
                    targetNode.graphVertex,
                    "perimeterSpacing=4;strokeWidth=4;labelBackgroundColor=white;fontStyle=1"
                  );
                }
              }
            }
          });
        }
      });
    } finally {
    }

    const layout = new mxHierarchicalLayout(graph, mxConstants.DIRECTION_NORTH);
    layout.execute(
      graph.getDefaultParent(),
      selectionCells.length === 0 ? null : selectionCells
    );

    allNodes.forEachNode((v) => {
      // console.log(v.meta.x +","+ v.meta.y +";"+v.graphVertex.geometry.x +","+v.graphVertex.geometry.y)
      if (v.graphVertex) {
        v.meta.x = v.graphVertex.geometry.x + 30;
        v.meta.y = v.graphVertex.geometry.y + 100;
        delete v.graphVertex;
      }
    });

    allNodes.resetBoundaries();

    endTime = new Date();
    let timeDiff = endTime - startTime; // in ms
    // strip the ms
    timeDiff /= 1000;
    // get seconds
    const seconds = Math.round(timeDiff);
    console.log(`drawGraph time: ${seconds} seconds`);
  }
}
