export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESSFULLY = "GET_CUSTOMER_SUCCESSFULLY";
export const ADD_BOT_TO_CUSTOMER = "ADD_BOT_TO_CUSTOMER";
export const DELETE_BOT_FROM_CUSTOMER = "DELETE_BOT_FROM_CUSTOMER";
export const TRAIN_BOT = "TRAIN_BOT";
export const SET_ENVS = "SET_ENVS";
export const GET_CUSTOMERS = "LOADING_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESSFULLY = "GET_CUSTOMERS_SUCCESSFULLY";
export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED";
export const BOT_DATA_UPDATE = "BOT_DATA_UPDATE";
export const GET_PYPES_SUCCESSFULLY = "GET_PYPES_SUCCESSFULLY";
export const UPDATE_PYPES_SUCCESSFULLY = "UPDATE_PYPES_SUCCESSFULLY";
export const GET_PYPE_STREAM_SUCCESSFULLY = "GET_PYPE_STREAM_SUCCESSFULLY";
export const GET_BOT_STREAM_SUCCESSFULLY = "GET_BOT_STREAM_SUCCESSFULLY";
export const SET_PYPE_LOADING = "SET_PYPE_LOADING";
export const SET_INTENT_DATA = "SET_INTENT_DATA";
export const SET_ALL_INTENTS = "SET_ALL_INTENTS";
export const SET_ENTITY_DATA = "SET_ENTITY_DATA";
export const RESET_INTENT_DATA = "RESET_INTENT_DATA";
export const RESET_ENTITY_DATA = "RESET_ENTITY_DATA";
export const BOT_WAS_UPLOADED_WITH_ERROR = "BOT_WAS_UPLOADED_WITH_ERROR";
export const BOT_WAS_UPLOADED_WITHOUT_ERROR = "BOT_WAS_UPLOADED_WITHOUT_ERROR";
export const GET_INTERFACES = "GET_INTERFACES";
export const REMOVE_INTERFACES = "REMOVE_INTERFACES";
export const SELECTED_INTERFACE = "SELECTED_INTERFACE";
export const SET_STOP_WORDS_EDITED = "SET_STOP_WORDS_EDITED";
export const SET_IF_TRAINING_NEEDED = "SET_IF_TRAINING_NEEDED";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const UPDATE_SOLUTION_INFO = "UPDATE_SOLUTION_INFO";
export const VALIDATE_SOLUTION = "VALIDATE_SOLUTION";
export const UPDATE_CHANNELS = "UPDATE_CHANNELS";
export const SET_PREVIEW_MODAL_OPEN = "SET_PREVIEW_MODAL_OPEN";
