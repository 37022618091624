export function decodeActionNodeInput(node_input) {
  const ret = [];
  if (node_input) {
    const buttons = node_input.split(",");

    buttons.forEach((button) => {
      const buttonParts = button.split(":");
      if (buttonParts.length === 2) {
        const subButtonParts = buttonParts[1].split("*");
        if (subButtonParts.length === 2) {
          ret.push({
            sourceNode: Number(subButtonParts[0]),
            sourceNodeName: subButtonParts[1],
            variableName: buttonParts[0],
          });
        }
      }
    });
  }
  return ret;
}

export function encodeActionNodeInput(node_input) {
  const ret = [];
  if (node_input) {
    return node_input
      .map(
        (button) =>
          `${button.variableName}:${button.sourceNode}*${button.sourceNodeName}`
      )
      .join(",");
  }
  return ret;
}

export function decodeActionWhatNext(whatNext) {
  const ret = [];
  if (whatNext) {
    const items = whatNext.split("|");

    items.forEach((item) => {
      const itemParts = item.split("~");
      if (itemParts.length === 2) {
        ret.push({ name: itemParts[0], next_node: Number(itemParts[1]) });
      }
    });
  }
  return ret;
}

export function encodeActionWhatNext(whatNext) {
  const ret = [];
  if (whatNext && Array.isArray(whatNext)) {
    return whatNext.map((item) => `${item.name}~${item.next_node}`).join("|");
  }
  return ret;
}

export function validateActionData(node, validateOnlyTouched, errors) {
  const { data, touched } = node;
  const { node_input, what_next } = data;
  if (
    (validateOnlyTouched && touched && touched.command) ||
    !validateOnlyTouched
  ) {
    const { command, output, decision_variable } = data;
    if (!command) {
      errors.command = "Command Name is required";
    } else if (
      command.charAt(0).toUpperCase() !== command.charAt(0) ||
      command.toUpperCase() === command ||
      command !== command.replace(/[^0-9a-z]/gi, "")
    ) {
      errors.command = "A valid Command Name is required";
    }

    if (!output) {
      errors.output = "Output Name is required";
    }
    if (!decision_variable) {
      errors.decision_variable = "Variable Name is required";
    }
  }

  if (node_input && Array.isArray(node_input)) {
    const node_input_errors = [];
    let found_inout_errors = false;
    const touched_node_input = touched ? touched.node_input : false;
    node_input.forEach((button, index) => {
      node_input_errors[index] = {};
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_node_input) &&
            touched_node_input.length > index &&
            touched_node_input[index] &&
            touched_node_input[index].sourceNode)) &&
        !button.sourceNode
      ) {
        node_input_errors[index].sourceNode = "Source node is required";
        found_inout_errors = true;
      }
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_node_input) &&
            touched_node_input.length > index &&
            touched_node_input[index] &&
            touched_node_input[index].sourceNodeName)) &&
        !button.sourceNodeName
      ) {
        node_input_errors[index].sourceNodeName =
          "Source node name is required";
        found_inout_errors = true;
      }
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_node_input) &&
            touched_node_input.length > index &&
            touched_node_input[index] &&
            touched_node_input[index].variableName)) &&
        !button.variableName
      ) {
        node_input_errors[index].variableName = "Variable Name is required";
        found_inout_errors = true;
      }
    });
    if (found_inout_errors) {
      errors.node_input = node_input_errors;
    }
  }

  if (what_next && Array.isArray(what_next)) {
    const what_next_errors = [];
    let found_inout_errors = false;
    const touched_what_next = touched ? touched.what_next : false;
    what_next.forEach((button, index) => {
      what_next_errors[index] = {};
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_what_next) &&
            touched_what_next.length > index &&
            touched_what_next[index] &&
            touched_what_next[index].name)) &&
        !button.name
      ) {
        what_next_errors[index].name = "Option name is required";
        found_inout_errors = true;
      }
      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_what_next) &&
            touched_what_next.length > index &&
            touched_what_next[index] &&
            touched_what_next[index].next_node)) &&
        !button.next_node
      ) {
        what_next_errors[index].next_node = "A next node is required";
        found_inout_errors = true;
      }
    });
    if (found_inout_errors) {
      errors.what_next = what_next_errors;
    }
  }
}
