import { validatorHTTPS } from "Designer/lib/validators";

export function decodeRichAssetContentAuthentication(rich_asset_content) {
  let ret = null;
  const richAssetContent = rich_asset_content;
  if (richAssetContent) {
    const parts = richAssetContent.split("~");
    if (parts.length === 2 || parts.length === 3) {
      ret = {
        button_message: parts[0],
        url: parts[1],
      };
    }
  }
  return ret;
}

export function encodeRichAssetContentAuthentication(richAssetContent) {
  return `${richAssetContent.button_message}~${richAssetContent.url}~${richAssetContent.button_message}`;
}

export function validateRichAssetContentAuthentication(
  richAssetContent,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  const errors = {};
  let foundError = false;
  if (
    richAssetContent &&
    (!validateOnlyTouched ||
      (validateOnlyTouched &&
        touched_rich_asset_content &&
        touched_rich_asset_content.url))
  ) {
    const urlError = validatorHTTPS(richAssetContent.url);
    if (urlError) {
      errors.url = urlError;
      foundError = true;
    }
  }
  if (
    richAssetContent &&
    (!validateOnlyTouched ||
      (validateOnlyTouched &&
        touched_rich_asset_content &&
        touched_rich_asset_content.button_message)) &&
    !richAssetContent.button_message
  ) {
    errors.button_message = "Button Text is required";
    foundError = true;
  }
  return foundError ? errors : undefined;
}
