import React from "react";
import { validatorHTTPS } from "Designer/lib/validators";
import defaultCarouselImg from "Designer/Assets/default-carousel-img.jpg";
import WarningExclamationSignIcon from "Designer/Assets/ico-warning-exclamation-sign-red.png";

export function decodeRichAssetContentCarousel(richAssetContent) {
  let richAssetContentObj = null;
  try {
    richAssetContentObj = JSON.parse(richAssetContent);
  } catch (e) {
    console.error(e);
    console.error(richAssetContent);
  }
  if (!Array.isArray(richAssetContentObj)) {
    return [];
  }
  return richAssetContentObj;
}

export function encodeRichAssetContentCarousel(richAssetContent) {
  if (Array.isArray(richAssetContent)) {
    richAssetContent.forEach((e) => {
      delete e.createAnother;
    });
  }
  return JSON.stringify(richAssetContent);
}

export function validateRichAssetContentCarousel(
  richAssetContent,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  const errors = [];
  let foundError = false;
  if (richAssetContent && Array.isArray(richAssetContent)) {
    richAssetContent.forEach((button, index) => {
      errors[index] = {};

      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index].title)) &&
        !button.title
      ) {
        errors[index].title = "Option name is required";
        foundError = true;
      }

      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index].label)) &&
        !button.label
      ) {
        errors[index].label = "Carousel cards must include button text";
        foundError = true;
      }

      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index].image)) &&
        !button.image
      ) {
        errors[index].image = validatorHTTPS(button.image);
        foundError = true;
      }

      if (
        (!validateOnlyTouched ||
          (validateOnlyTouched &&
            Array.isArray(touched_rich_asset_content) &&
            touched_rich_asset_content.length >= index + 1 &&
            touched_rich_asset_content[index].dest)) &&
        !button.dest
      ) {
        errors[index].dest = "A next node is required";
        foundError = true;
      }
    });
  }
  return foundError ? errors : undefined;
}

export function getCard(
  cardData,
  index,
  updateRichContentButtonSelected,
  isSelected,
  isValid = true
) {
  return (
    <div style={{ margin: "12px auto", display: "flex" }}>
      <div
        className={`embed-option-${index} carousel-card ellipsis node-button ${
          isSelected ? "selected " : ""
        }${isValid ? "" : "error"}`}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        onClick={
          index !== null ? updateRichContentButtonSelected(index) : undefined
        }
      >
        <div>
          <div className="landscape">
            <img
              alt={cardData.title}
              src={cardData.image ? cardData.image : defaultCarouselImg}
            />
          </div>
          <div
            className={`title ellipsis ${cardData.title ? "" : "placeholder"}`}
          >
            {cardData.title || `Item name ${index !== null ? index : ""}`}
          </div>
          <div
            className={`subtitle ellipsis ${
              cardData.subtitle ? "" : "placeholder"
            }`}
          >
            {cardData.subtitle || "Subtitle"}
          </div>
          <div
            className={`opt-text ellipsis ${
              cardData.opt_text ? "" : "placeholder"
            }`}
          >
            {cardData.opt_text || "Subtext"}
          </div>
          <hr />
          <div
            className={`label ellipsis ${cardData.label ? "" : "placeholder"}`}
          >
            {cardData.label || "Select"}
          </div>
        </div>
      </div>
      {!isValid && (
        <div>
          <img
            alt=""
            src={WarningExclamationSignIcon}
            className="error-exclamation-sign"
          />
        </div>
      )}
    </div>
  );
}
