/* eslint-disable no-unused-vars */
/* eslint-disable no-async-promise-executor */
import {
  BEHAVIORS_DISABLE_INPUT,
  DEFAULT_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
  RICH_ASSET_TYPE_BUTTON,
  RICH_ASSET_TYPE_CAROUSEL,
  RICH_ASSET_TYPE_DATEPICKER,
  RICH_ASSET_TYPE_DOCUMENT,
  RICH_ASSET_TYPE_FILE_UPLOAD,
  RICH_ASSET_TYPE_IMAGEBUTTON,
  RICH_ASSET_TYPE_LOCATION,
  RICH_ASSET_TYPE_LOGIN_BUTTON,
  RICH_ASSET_TYPE_IMAGE,
  RICH_ASSET_TYPE_SECURE,
  RICH_ASSET_TYPE_SELECTION,
  RICH_ASSET_TYPE_LISTPICKER,
  RICH_ASSET_TYPE_TIMEPICKER,
  RICH_ASSET_TYPE_VIDEO,
  RICH_ASSET_TYPE_WEBVIEW,
  RICH_ASSET_TYPE_PERSISTENT_MENU,
  DYNAMIC_SUPPORTED_RICH_ASSET_TYPES,
} from "Designer/lib/defs";
import DG from "Designer/lib/DG/DG";
import NodeContainer from "Designer/lib/nodesContainer";
import { uniq } from "Designer/lib/utills";
import { validateRichAssetContentButtonsOrList } from "Designer/Components/richContents/buttonsAndList/utils";
import { validateRichAssetContentRichMedia } from "Designer/Components/richContents/richMedia/utils";
import { validateRichAssetContentCarousel } from "Designer/Components/richContents/carousel/utils";
import { validateRichAssetContentDocument } from "Designer/Components/richContents/document/utils";
import { validateRichAssetContentWebView } from "Designer/Components/richContents/webview/utils";
import { validateRichAssetContentSecure } from "Designer/Components/richContents/secure/utils";
import {
  createFileUploadGroup,
  validateRichAssetContentFileUpload,
} from "Designer/Components/richContents/fileUpload/utils";
import { validateRichAssetContentLocation } from "Designer/Components/richContents/location/utils";
import { validateActionData } from "Designer/Components/richContents/action/utils";
import { validateRichAssetContentAuthentication } from "Designer/Components/richContents/authentication/utils";
import Papa from "papaparse";
import * as t from "./actionTypes";
import botManagerAPI from "../../bot-manager-api";
import {
  getDefaultNode,
  getOutOfScopeNode,
  getDescenders,
  getNodesReadyForCsv,
  importCsvFromStream,
  getPredefinedNodes,
  getRootGroupNode,
} from "../lib/botUtills";
import config from "../../config";
import { initBot } from "../../Manager/Store/actions";
import { PRESERVE_NODE_NUMBERS } from "../lib/defs";
import activityTracker from "../../activityTracker";
import { validateRichAssetContentPersistentMenu } from "../Components/richContents/persistentMenu/utils";

const INTENT_DEFAULT_FILE_URL = `${config.homepage}/intent.default.csv`;
const ENTITY_DEFAULT_FILE_URL = `${config.homepage}/entity.default.csv`;

export function updateZoomLevel(
  zoomLevel,
  mouseX = undefined,
  mouseY = undefined
) {
  return (dispatch /* , getState */) => {
    dispatch({
      type: t.UPDATE_ZOOM_LEVEL,
      zoomLevel,
      mouseX,
      mouseY,
    });
  };
}

export function updateSvgArea(area) {
  return (dispatch /* , getState */) => {
    dispatch({
      type: t.UPDATE_SVG_AREA,
      area,
    });
  };
}

function updateNodesToStore(
  nodes,
  selectedNodeId,
  selectedGroup,
  alignToRoot = false,
  initialBotLoad = false
) {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_NODES,
      nodes,
      selectedNodeId,
      selectedGroup,
      alignToRoot,
      initialBotLoad,
    });
  };
}

export function updateLocationAndBoundaries(number, x, y) {
  return (dispatch, getState) => {
    const state = getState();
    const nodes = state.designer.present.nodes;

    //when the moved node is the source
    const node = nodes.getNodeClone(number);
    if (node) {
      nodes.updateNodeLocation(number, x, y);
      dispatch(updateNodesToStore(nodes, undefined, undefined));
    }
  };
}

export function nodesReceived(
  nodes,
  alignToRoot,
  fromLocalImport = false,
  selectedGroup = PRESERVE_NODE_NUMBERS.RootGroup,
  initialBotLoad = false
) {
  return updateNodesToStore(
    nodes,
    undefined,
    selectedGroup,
    alignToRoot,
    initialBotLoad
  );
}

export function selectNode(nodeId) {
  return (dispatch, getState) => {
    const state = getState();

    if (state.designer.present.selectedNodeId !== nodeId) {
      const nodes = state.designer.present.nodes;
      const prevSelectedNode = nodes.getNodeClone(
        state.designer.present.selectedNodeId
      );
      if (prevSelectedNode && prevSelectedNode.meta.isNew) {
        prevSelectedNode.meta.isNew = false;
        validateNode(prevSelectedNode, null, null, nodes);
        nodes.updateNode(prevSelectedNode);
        dispatch(updateNodesToStore(nodes, nodeId, undefined)); // also select the node
      } else {
        dispatch({
          type: t.NODE_SELECTED,
          nodeId,
        });
      }
    }
  };
}

export function openGroup(nodeId) {
  return {
    type: t.OPEN_GROUP,
    nodeId,
  };
}

export function selectEmbedIndex(embedIndex) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.designer.present.selectedEmbedIndex !== embedIndex) {
      dispatch({
        type: t.EMBED_OPTION_SELECT,
        embedIndex,
      });
    }
  };
}

export function markAsTouched(
  field,
  index,
  subField,
  isPersistentNode = false
) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.designer.present.selectedNodeId) {
      const nodes = state.designer.present.nodes;
      let node = null;

      if (isPersistentNode) {
        node = nodes.getNodeClone(PRESERVE_NODE_NUMBERS.PersistentMenuNode);
      } else {
        node = nodes.getNodeClone(state.designer.present.selectedNodeId);
      }

      if (node.meta.isNew) {
        if (!node.touched) {
          node.touched = { ref: 0 };
        }

        node.touched.ref += 1;

        if (index === undefined && subField === undefined) {
          node.touched[field] = true;
        } else if (index === undefined && subField !== undefined) {
          if (!node.touched[field]) {
            node.touched[field] = {};
          }
          node.touched[field][subField] = true;
        } else if (subField === undefined) {
          if (!node.touched[field]) {
            node.touched[field] = Array(index + 1);
          } else if (node.touched[field].length < index + 1) {
            node.touched[field].length = index + 1;
          }
          node.touched[field][index] = true;
        } else {
          if (!node.touched[field]) {
            node.touched[field] = Array(index + 1);
          } else if (node.touched[field].length < index + 1) {
            node.touched[field].length = index + 1;
          }
          if (!node.touched[field][index]) {
            node.touched[field][index] = {};
          }
          node.touched[field][index][subField] = true;
        }

        validateNode(node, null, null, nodes, true);
        nodes.updateNode(node);
        dispatch(updateNodesToStore(nodes, undefined, undefined));
      }
    }
  };
}

export function selectEdge(nodeNumber, edgeIndex) {
  return {
    type: t.EDGE_SELECTED,
    nodeNumber,
    edgeIndex,
  };
}

export function AutoArrangeNodes() {
  return (dispatch, getState) => {
    const state = getState();
    let { nodes, selectedGroup } = state.designer.present;
    const selectedGroupNode = nodes.getNodeRead(selectedGroup);
    const groupLevelNodes = selectedGroupNode
      ? selectedGroupNode.meta.childNodes
      : [];
    DG.drawGraph(nodes, selectedGroup, groupLevelNodes);

    dispatch(nodesReceived(nodes, false, true, selectedGroup));
  };
}

function setDefaultRichAssetContent(updatedNode, oldType, nodes, dispatch) {
  const updatedNodeData = updatedNode.data;
  if (
    (oldType === RICH_ASSET_TYPE_BUTTON &&
      updatedNodeData.rich_asset_type === RICH_ASSET_TYPE_IMAGEBUTTON) ||
    (oldType === RICH_ASSET_TYPE_IMAGEBUTTON &&
      updatedNodeData.rich_asset_type === RICH_ASSET_TYPE_BUTTON)
  )
    return;

  let actionNodeNumber;

  switch (updatedNodeData.rich_asset_type) {
    case RICH_ASSET_TYPE_BUTTON:
    case RICH_ASSET_TYPE_IMAGEBUTTON:
    case RICH_ASSET_TYPE_SELECTION:
      updatedNodeData.rich_asset_content = {
        isDynamic: false,
        options: [{ name: "", next_node: null }],
      };
      updatedNodeData.answer_required = 1;
      break;
    case RICH_ASSET_TYPE_PERSISTENT_MENU:
      if (!updatedNodeData.rich_asset_content) {
        updatedNodeData.rich_asset_content = { isDynamic: false, options: [] };
      }
      break;
    case RICH_ASSET_TYPE_LISTPICKER:
      updatedNodeData.rich_asset_content = {
        isDynamic: false,
        options: [{ name: "", next_node: null, description: "", alt_text: "" }],
      };
      break;
    case RICH_ASSET_TYPE_WEBVIEW:
      updatedNodeData.rich_asset_content = { name: "", url: "" };
      updatedNodeData.answer_required = 1;
      updatedNodeData.nlu_disabled = 1;
      updatedNodeData.behaviors = BEHAVIORS_DISABLE_INPUT;
      break;
    case RICH_ASSET_TYPE_CAROUSEL:
      updatedNodeData.rich_asset_content = [
        {
          title: "",
          image: "",
          subtitle: "",
          opt_text: "",
          label: "",
          dest: "",
        },
      ];
      updatedNodeData.answer_required = 1;
      break;
    case RICH_ASSET_TYPE_DOCUMENT:
      updatedNodeData.rich_asset_content = { url: "", firstTimeFetch: true };
      break;
    case RICH_ASSET_TYPE_IMAGE:
      updatedNodeData.rich_asset_content = {
        url: "",
        alt_text: "",
        hasError: false,
        type: "static",
      };
      break;
    case RICH_ASSET_TYPE_VIDEO:
      updatedNodeData.rich_asset_content = { url: "", hasError: false };
      break;
    case RICH_ASSET_TYPE_DATEPICKER:
    case RICH_ASSET_TYPE_TIMEPICKER:
      updatedNodeData.behaviors = BEHAVIORS_DISABLE_INPUT;
      updatedNodeData.message = null;
      updatedNodeData.answer_required = 1;
      updatedNodeData.nlu_disabled = 1;
      updatedNodeData.rich_asset_content = "";
      break;
    case RICH_ASSET_TYPE_SECURE:
      updatedNodeData.rich_asset_content = updatedNodeData.message || "";
      updatedNodeData.message = null;
      updatedNodeData.answer_required = 1;
      break;
    case RICH_ASSET_TYPE_LOGIN_BUTTON:
      updatedNodeData.rich_asset_content = { url: "", button_message: "" };
      updatedNodeData.answer_required = 1;
      break;
    case RICH_ASSET_TYPE_FILE_UPLOAD:
      updatedNodeData.rich_asset_content = {
        type: "direct_post",
        endpoint: "",
        upload_label: "Upload",
        cancel_label: "Cancel",
        headers: [{ key: "", value: "" }],
      };
      updatedNodeData.answer_required = 1;
      updatedNodeData.nlu_disabled = 1;
      updatedNodeData.behaviors = BEHAVIORS_DISABLE_INPUT;
      createFileUploadGroup(updatedNode, nodes, dispatch);
      break;
    case RICH_ASSET_TYPE_LOCATION:
      updatedNodeData.answer_required = 1;
      updatedNodeData.nlu_disabled = 1;
      updatedNodeData.rich_asset_content = updatedNodeData.message || "";
      actionNodeNumber = nodes.getNewNodeNumber();
      updatedNodeData.next_nodes = [actionNodeNumber];
      updatedNodeData.behaviors = BEHAVIORS_DISABLE_INPUT;
      updatedNode.meta.group_root = updatedNodeData.number;
      dispatch(
        addNode(
          "D",
          {
            x: updatedNode.meta.x - DEFAULT_NODE_WIDTH,
            y:
              updatedNode.meta.y +
              updatedNode.meta.height +
              3 * DEFAULT_NODE_HEIGHT,
          },
          "Verify-Success",
          actionNodeNumber + 1,
          false,
          undefined,
          undefined,
          updatedNodeData.number
        )
      );
      dispatch(
        addNode(
          "D",
          {
            x: updatedNode.meta.x + DEFAULT_NODE_WIDTH,
            y:
              updatedNode.meta.y +
              updatedNode.meta.height +
              3 * DEFAULT_NODE_HEIGHT,
          },
          "Verify-Fail",
          actionNodeNumber + 2,
          false,
          undefined,
          undefined,
          updatedNodeData.number
        )
      );
      dispatch(
        addNode(
          "A",
          {
            x: updatedNode.meta.x,
            y:
              updatedNode.meta.y +
              updatedNode.meta.height +
              DEFAULT_NODE_HEIGHT,
          },
          "VerifyGPS",
          actionNodeNumber,
          false,
          [
            { name: "true", next_node: actionNodeNumber + 1 },
            { name: "false", next_node: actionNodeNumber + 2 },
          ],
          "VerifyGPS",
          updatedNodeData.number
        )
      );
      break;
    default:
      updatedNodeData.rich_asset_content = undefined;
      break;
  }
}

function setDefaultRichAssetContentIfDynamicChanged(
  updatedNode,
  oldNode,
  oldValue,
  nodes,
  dispatch
) {
  if (
    updatedNode.data.rich_asset_type &&
    DYNAMIC_SUPPORTED_RICH_ASSET_TYPES.includes(
      updatedNode.data.rich_asset_type
    )
  ) {
    if (
      updatedNode.data.rich_asset_content.isDynamic &&
      !oldNode.data.rich_asset_content.isDynamic
    ) {
      updatedNode.data.rich_asset_content = { isDynamic: true, options: [] };
    } else if (
      !updatedNode.data.rich_asset_content.isDynamic &&
      oldNode.data.rich_asset_content.isDynamic
    ) {
      updatedNode.data.rich_asset_content = { isDynamic: false };
      setDefaultRichAssetContent(updatedNode, oldValue, nodes, dispatch);
    }
  }
}

export function validateNode(
  updatedNode,
  field,
  oldValue,
  allNodes,
  validateOnlyTouched = false
) {
  let errors = {};

  // name validation
  if (
    updatedNode.data.type === "D" &&
    ((validateOnlyTouched && updatedNode.touched && updatedNode.touched.name) ||
      !validateOnlyTouched)
  ) {
    if (!updatedNode.data.name) {
      errors.name = "Nodes must have a unique name";
    } else {
      const nodeNameLoweCase = updatedNode.data.name.toLowerCase();
      const nodesWithSameName = allNodes.filterNodes(
        (node) => node.data.name.toLowerCase() === nodeNameLoweCase
      );
      if (nodesWithSameName.length !== 0) {
        if (
          nodesWithSameName.length !== 1 ||
          nodesWithSameName[0].data.number !== updatedNode.data.number
        ) {
          errors.name = "Nodes must have a unique name";
          nodesWithSameName.forEach(({ data: { number } }) => {
            const cloneNode = allNodes.getNodeClone(number);
            if (!cloneNode.errors) {
              cloneNode.errors = {};
            }
            cloneNode.errors.name = "Nodes must have a unique name";
            allNodes.updateNode(cloneNode);
          });
        }
      }
    }
  }
  if (updatedNode.data.type === "D" && field === "name") {
    // remove uniqueness from other
    const oldValueNameLoweCase = oldValue.toLowerCase();
    const nodesWithSaneName = [];
    allNodes.forEachNode((n) => {
      if (n.data.name.toLowerCase() === oldValueNameLoweCase) {
        nodesWithSaneName.push(n);
      }
    });
    if (nodesWithSaneName.length === 2) {
      //only one node is left with that name + the old node
      nodesWithSaneName.forEach((n) => {
        if (n.errors !== undefined && n.errors.name) {
          const cloneNode = allNodes.getNodeClone(n.data.number);
          delete cloneNode.errors.name;
          if (Object.keys(cloneNode.errors).length === 0) {
            delete cloneNode.errors;
          }
          allNodes.updateNode(cloneNode);
        }
      });
    }
  }

  // message validation
  if (
    updatedNode.data.type === "D" &&
    ((validateOnlyTouched &&
      updatedNode.touched &&
      updatedNode.touched.message) ||
      !validateOnlyTouched)
  ) {
    if (
      updatedNode.data.rich_asset_type !== RICH_ASSET_TYPE_SECURE &&
      updatedNode.data.rich_asset_type !== RICH_ASSET_TYPE_DATEPICKER &&
      updatedNode.data.rich_asset_type !== RICH_ASSET_TYPE_TIMEPICKER &&
      updatedNode.data.rich_asset_type !== RICH_ASSET_TYPE_LOCATION &&
      updatedNode.data.rich_asset_type !== RICH_ASSET_TYPE_PERSISTENT_MENU &&
      updatedNode.data.type === "D"
    ) {
      if (!updatedNode.data.message) {
        errors.message = "Nodes must include a message";
      }
    }
  }

  if (updatedNode.data.type === "A") {
    validateActionData(updatedNode, validateOnlyTouched, errors);
  }

  let richContentErrors = {};
  let someErrors;
  // validate Rich Content
  switch (updatedNode.data.rich_asset_type) {
    case RICH_ASSET_TYPE_BUTTON:
    case RICH_ASSET_TYPE_IMAGEBUTTON:
    case RICH_ASSET_TYPE_SELECTION:
    case RICH_ASSET_TYPE_LISTPICKER:
      richContentErrors = validateRichAssetContentButtonsOrList(
        updatedNode.data.rich_asset_content,
        updatedNode.data.rich_asset_type,
        allNodes,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_PERSISTENT_MENU:
      richContentErrors = validateRichAssetContentPersistentMenu(
        updatedNode.data.rich_asset_content,
        allNodes,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_WEBVIEW:
      richContentErrors = validateRichAssetContentWebView(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_CAROUSEL:
      richContentErrors = validateRichAssetContentCarousel(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_DOCUMENT:
      richContentErrors = validateRichAssetContentDocument(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_IMAGE:
    case RICH_ASSET_TYPE_VIDEO:
      richContentErrors = validateRichAssetContentRichMedia(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_DATEPICKER:
    case RICH_ASSET_TYPE_TIMEPICKER:
      break;
    case RICH_ASSET_TYPE_SECURE:
      richContentErrors = validateRichAssetContentSecure(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_LOGIN_BUTTON:
      richContentErrors = validateRichAssetContentAuthentication(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    case RICH_ASSET_TYPE_FILE_UPLOAD:
      someErrors = validateRichAssetContentFileUpload(
        updatedNode.data,
        updatedNode.meta.isNew ? updatedNode.touched : null,
        validateOnlyTouched
      );
      if (someErrors) {
        errors = Object.assign({}, errors, someErrors);
      }
      break;
    case RICH_ASSET_TYPE_LOCATION:
      richContentErrors = validateRichAssetContentLocation(
        updatedNode.data.rich_asset_content,
        updatedNode.meta.isNew ? updatedNode.touched.rich_asset_content : null,
        validateOnlyTouched
      );
      break;
    default:
      break;
  }

  if (
    richContentErrors &&
    ((Array.isArray(richContentErrors) && richContentErrors.length) ||
      Object.keys(richContentErrors).length)
  ) {
    errors.rich_asset_content = richContentErrors;
  }

  if (Object.keys(errors).length === 0) {
    delete updatedNode.errors;
  } else {
    updatedNode.errors = errors;
  }
}

let lastUpdatedNodeNumber = undefined;
function updateNodeFieldInternal(
  nodeNumber,
  field,
  value,
  forceUpdate,
  dispatch,
  getState
) {
  const state = getState();
  const nodes = state.designer.present.nodes;
  if (nodes) {
    const oldNode = nodes.getNodeRead(nodeNumber);
    const updatedNode = nodes.getNodeClone(nodeNumber);

    if (lastUpdatedNodeNumber !== nodeNumber) {
      activityTracker.logEvent(activityTracker.eventTypeNames.EDIT_NODE, {
        nodeName: oldNode.data.name || (field === "name" ? value : "<ampty>"),
        solutionName: `${state.designer.present.customerId}:${state.designer.present.botId}`,
      });
      lastUpdatedNodeNumber = nodeNumber;
    }

    if (updatedNode) {
      if (forceUpdate || updatedNode.data[field] !== value) {
        const oldValue = updatedNode.data[field];
        updatedNode.data[field] = value;
        if (field === "rich_asset_type") {
          nodes.forEachNode((node) => {
            if (node.meta.group_root === nodeNumber)
              nodes.deleteNode(node.data.number);
          });
          updatedNode.data.next_nodes = [];
          setDefaultRichAssetContent(updatedNode, oldValue, nodes, dispatch);
          dispatch(selectEmbedIndex(-1));
        } else if (field === "rich_asset_content") {
          setDefaultRichAssetContentIfDynamicChanged(
            updatedNode,
            oldNode,
            oldValue,
            nodes,
            dispatch
          );
          updatedNode.rich_asset_type !==
            PRESERVE_NODE_NUMBERS.PersistentMenuNode &&
            updateNextNodes(updatedNode.data);
        } else if (field === "what_next") {
          updatedNode.data.next_nodes = uniq(
            updatedNode.data.what_next.map((i) => i.next_node).filter((n) => n)
          );
        } else if (field === "command") {
          updatedNode.data.name = value;
        } else if (field === "answer_required") {
          if (
            value &&
            !updatedNode.meta.ADUR &&
            (!updatedNode.data.rich_asset_type ||
              updatedNode.data.rich_asset_type === RICH_ASSET_TYPE_SECURE ||
              updatedNode.data.rich_asset_type === RICH_ASSET_TYPE_DATEPICKER ||
              updatedNode.data.rich_asset_type === RICH_ASSET_TYPE_TIMEPICKER ||
              updatedNode.data.rich_asset_type === RICH_ASSET_TYPE_WEBVIEW ||
              updatedNode.data.rich_asset_type === RICH_ASSET_TYPE_LOCATION)
          ) {
            updatedNode.meta.ADUR = true;
            updatedNode.data.nlu_disabled = 1;
          }
        }
        validateNode(
          updatedNode,
          field,
          oldValue,
          nodes,
          updatedNode.meta.isNew
        );
        nodes.updateNode(updatedNode, field);
        dispatch(updateNodesToStore(nodes, undefined, undefined));
      }
    }
  }
}

export function updateNodeField(
  nodeNumber,
  field,
  value,
  wasBotModified = true
) {
  return (dispatch, getState) => {
    updateNodeFieldInternal(
      nodeNumber,
      field,
      value,
      false,
      dispatch,
      getState,
      wasBotModified
    );
  };
}

function updateNextNodes(data) {
  if (DYNAMIC_SUPPORTED_RICH_ASSET_TYPES.includes(data.rich_asset_type)) {
    if (data.rich_asset_content.isDynamic) {
      data.next_nodes = [];
      return;
    }
  }
  switch (data.rich_asset_type) {
    case RICH_ASSET_TYPE_BUTTON:
    case RICH_ASSET_TYPE_IMAGEBUTTON:
    case RICH_ASSET_TYPE_SELECTION:
    case RICH_ASSET_TYPE_LISTPICKER:
      data.next_nodes = uniq(
        data.rich_asset_content.options.map((i) => i.next_node).filter((n) => n)
      );
      break;
    case RICH_ASSET_TYPE_CAROUSEL:
      data.next_nodes = uniq(
        data.rich_asset_content.map((i) => Number(i.dest)).filter((n) => n)
      );
      break;
    default:
      break;
  }
}

function removeFromNextNodes(sourceData, targetNumber) {
  switch (sourceData.rich_asset_type) {
    case RICH_ASSET_TYPE_BUTTON:
    case RICH_ASSET_TYPE_IMAGEBUTTON:
    case RICH_ASSET_TYPE_SELECTION:
    case RICH_ASSET_TYPE_LISTPICKER:
      if (sourceData.rich_asset_content.options) {
        sourceData.rich_asset_content.options.forEach((ec) => {
          if (ec.next_node === targetNumber) ec.next_node = null;
        });
      }
      sourceData.next_nodes = uniq(
        sourceData.rich_asset_content.options
          .map((i) => i.next_node)
          .filter((n) => n)
      );
      break;
    case RICH_ASSET_TYPE_CAROUSEL:
      sourceData.rich_asset_content.forEach((ec) => {
        if (Number(ec.dest) === targetNumber) ec.dest = "";
      });
      sourceData.next_nodes = uniq(
        sourceData.rich_asset_content
          .map((i) => Number(i.dest))
          .filter((n) => n)
      );
      break;
    default:
      if (Array.isArray(sourceData.next_nodes)) {
        sourceData.next_nodes = sourceData.next_nodes.filter(
          (n) => n !== targetNumber
        );
      }
      break;
  }
}

export function addNode(
  type,
  coordinate,
  name,
  number,
  setFocusOnNewNode = true,
  what_next = undefined,
  command = undefined,
  groupRoot = undefined,
  additionalPersProps = false
) {
  return (dispatch, getState) => {
    const state = getState();
    const { nodes, selectedNodeId, selectedGroup } = state.designer.present;
    const newNodeNumber = number || nodes.getNewNodeNumber();

    const selectNode = nodes.getNodeRead(selectedNodeId);
    if (selectNode) {
      //validate prev node
      validateNode(selectNode, null, null, nodes, false);
    }

    if (!coordinate) {
      coordinate = {
        x: selectNode.meta.x,
        y: selectNode.meta.y + selectNode.meta.height + 100,
      };
    }

    const node = {
      data: {
        number: newNodeNumber,
        type,
        name: name || "",
        next_nodes: [],
        what_next: [],
        variableCheckBox: false,
      },
      meta: {
        x: coordinate.x,
        y: coordinate.y,
        width: DEFAULT_NODE_WIDTH,
        height: DEFAULT_NODE_HEIGHT,
        edges: [],
        isNew: true,
        groupNumber: selectedGroup,
      },
      touched: { ref: 0 },
    };
    // validateNode(node,"name","",nodes);
    if (what_next) {
      node.data.what_next = what_next;
      node.data.next_nodes = uniq(
        what_next.map((i) => i.next_node).filter((n) => n)
      );
    }

    if (command) {
      node.data.command = command;
    }

    if (groupRoot) {
      node.meta.group_root = groupRoot;
    }

    if (type === "A") {
      node.data.node_input = [{}];
      node.data.what_next = [{}];
    }

    // Only for persistent Menu Node, adding rich asset type and content via additionProps
    if (
      number === PRESERVE_NODE_NUMBERS.PersistentMenuNode &&
      additionalPersProps
    ) {
      node.data.rich_asset_type = RICH_ASSET_TYPE_PERSISTENT_MENU;
      node.data.rich_asset_content = {
        options: [],
      };
      node.meta.isNew = false;
      node.touched = {};
      node.data.nlu_disabled = 1;
    }

    //adding the node to its parent childNodes
    const selectedGroupNode = nodes.getNodeClone(selectedGroup);
    if (selectedGroupNode) {
      selectedGroupNode.meta.childNodes.push(newNodeNumber);
    }
    nodes.updateNode(selectedGroupNode);
    nodes.insertNode(node);

    dispatch(
      updateNodesToStore(
        nodes,
        setFocusOnNewNode ? newNodeNumber : undefined,
        undefined
      )
    );
  };
}

export function addPersistentMenuNode() {
  return (dispatch, getState) => {
    addPersistentMenuNodeInternal(dispatch, getState);
  };
}

function addPersistentMenuNodeInternal(dispatch, getState) {
  const state = getState();
  const { nodes } = state.designer.present;
  // Here we have to add code to check if the Persistent Menu node already exists.
  if (!nodes.getNodeRead(PRESERVE_NODE_NUMBERS.PersistentMenuNode)) {
    dispatch(
      addNode(
        "D",
        { x: 0, y: 0 },
        "Persistent Menu",
        PRESERVE_NODE_NUMBERS.PersistentMenuNode,
        false,
        undefined,
        undefined,
        undefined,
        true
      )
    );
  }
}

export function connectNodes(
  sourceNumber,
  sourceNodeOptionIndex,
  targetNumber
) {
  return (dispatch, getState) => {
    const state = getState();
    const nodes = state.designer.present.nodes;
    const sourceNode = nodes.getNodeRead(sourceNumber);
    const targetNode = nodes.getNodeRead(targetNumber);
    if (sourceNode && targetNode) {
      if (
        sourceNodeOptionIndex !== null &&
        sourceNodeOptionIndex !== undefined
      ) {
        const updatedNode = Object.assign({}, sourceNode.data);
        if (
          sourceNode.data.type === "D" &&
          Array.isArray(sourceNode.data.rich_asset_content)
        ) {
          const rich_asset_content = updatedNode.rich_asset_content;
          if (
            Array.isArray(rich_asset_content) &&
            rich_asset_content.length > sourceNodeOptionIndex
          ) {
            if (updatedNode.rich_asset_type === RICH_ASSET_TYPE_CAROUSEL) {
              rich_asset_content[sourceNodeOptionIndex].dest =
                String(targetNumber);
            } else {
              rich_asset_content[sourceNodeOptionIndex].next_node =
                targetNumber;
            }
            return updateNodeFieldInternal(
              sourceNumber,
              "rich_asset_content",
              rich_asset_content,
              true,
              dispatch,
              getState
            );
          }
        } else if (sourceNode.data.type === "A") {
          const whatNext = updatedNode.what_next;
          if (
            Array.isArray(whatNext) &&
            whatNext.length > sourceNodeOptionIndex
          ) {
            whatNext[sourceNodeOptionIndex].next_node = targetNumber;
            return updateNodeFieldInternal(
              sourceNumber,
              "what_next",
              whatNext,
              true,
              dispatch,
              getState
            );
          }
        }
      } else {
        return updateNodeFieldInternal(
          sourceNumber,
          "next_nodes",
          [targetNumber],
          true,
          dispatch,
          getState
        );
      }
    }
  };
}

export function deleteNode(number) {
  return (dispatch, getState) => {
    const state = getState();
    const { nodes, selectedGroup } = state.designer.present;
    const deletedNode = nodes.getNodeRead(number);
    if (deletedNode && !deletedNode.meta.readOnly) {
      if (deletedNode.data.type === "G") {
        const descenders = [];
        getDescenders(deletedNode, nodes, descenders);
        descenders.forEach((node) => {
          nodes.deleteNode(node.data.number);
        });
      }

      //removing the node from prev group (using filter in case the node is there more than once);
      const parentGroup = nodes.getNodeClone(deletedNode.meta.groupNumber);
      parentGroup.meta.childNodes = parentGroup.meta.childNodes.filter(
        (childNodeNumber) => childNodeNumber !== number
      );
      nodes.updateNode(parentGroup);

      nodes.deleteNode(number);

      nodes.forEachNode((node) => {
        if (node.meta.group_root === number) nodes.deleteNode(node.data.number);
      });

      nodes.forEachNode((sourceNode) => {
        if (sourceNode.data.next_nodes.some((i) => i === number)) {
          removeFromNextNodes(sourceNode.data, number);
          validateNode(sourceNode, null, null, nodes, false);
          nodes.updateNode(sourceNode);
        }
      });

      dispatch(updateNodesToStore(nodes, undefined, selectedGroup));
    }
  };
}

function getDefaultNodes() {
  const nodes = getPredefinedNodes();
  const defaultNode = getDefaultNode();
  const rootGroup = getRootGroupNode();
  rootGroup.meta.childNodes.push(defaultNode.data.number);
  nodes.push(rootGroup);
  nodes.push(defaultNode);
  nodes.push(getOutOfScopeNode(2));
  return nodes;
}

export function clearNodes() {
  return (dispatch) => {
    const nodes = getDefaultNodes();

    const allNodes = new NodeContainer();
    allNodes.insertNodes(nodes);

    dispatch(
      updateNodesToStore(allNodes, undefined, PRESERVE_NODE_NUMBERS.RootGroup)
    );
  };
}

export function unsetAlignToRoot() {
  return {
    type: t.UNSET_ALIGN_TO_ROOT,
  };
}

export function setGlobalVariables(globalVariables) {
  return {
    type: t.SET_GLOBAL_VARIABLES,
    globalVariables,
  };
}

export function clearDesignerData() {
  return {
    type: t.CLEAR_DESIGNER_DATA,
  };
}

export function loadBotFromCache(customerId, botId) {
  return (dispatch) => {
    let allNodes = new NodeContainer();
    const theBot = window.localStorage.getItem(`bot:${customerId}:${botId}`);
    if (theBot) {
      try {
        const data = JSON.parse(theBot);
        data.nodes.forEach(({ data }) => {
          if (
            data.rich_asset_type === RICH_ASSET_TYPE_DOCUMENT &&
            data.rich_asset_content
          ) {
            delete data.rich_asset_content.img_src;
            delete data.rich_asset_content.isFetching;
            delete data.rich_asset_content.wasFetched;
          }
        });

        allNodes.insertNodes(data.nodes);
        allNodes.forEachNode((node) => {
          validateNode(node, null, null, allNodes, false);
          allNodes.updateNode(node);
        });
      } catch (e) {
        console.error(e);
      }
    }
    dispatch(
      nodesReceived(
        allNodes,
        false,
        true,
        PRESERVE_NODE_NUMBERS.RootGroup,
        true
      )
    );
  };
}

export function loadBotFromServer(customerId, botId) {
  return async (dispatch, getState) => {
    const { customers } = getState().manager;
    const ret = new NodeContainer();
    ret.insertNodes(getDefaultNodes());
    if (customers[customerId] && customers[customerId].botsData[botId]) {
      const { latestVersion } = customers[customerId].botsData[botId];
      if (latestVersion) {
        const serverFiles = Object.values(latestVersion.files);
        if (serverFiles.indexOf("templates/bot.csv") > -1) {
          const response = await botManagerAPI.downloadTemplateFile(
            latestVersion.id,
            "templates/bot.csv"
          );
          dispatch(importCsvFromStream(response.file_data, false));
          return;
        }
      }
    }
    dispatch(
      nodesReceived(ret, false, true, PRESERVE_NODE_NUMBERS.RootGroup, true)
    );
  };
}

export function setBotId(customerId, botId) {
  return {
    type: t.SET_BOT_ID,
    customerId,
    botId,
  };
}

export function uploadBot(updateProgressCallback) {
  return (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: t.BOT_IS_UPLOADIND,
        });

        const state = getState();
        const { nodes, customerId, botId } = state.designer.present;
        const { customers } = state.manager;

        let intentFileBlob = null;
        let entityFileBlob = null;

        let filesCount = 0;
        updateProgressCallback(`Preparing solution files ${filesCount}/3`);
        if (customers[customerId] && customers[customerId].botsData[botId]) {
          const { latestVersion, hasDraft } =
            customers[customerId].botsData[botId];
          if (latestVersion) {
            const serverFiles = Object.values(latestVersion.files);
            if (serverFiles.indexOf("templates/intent.csv") > -1) {
              const intentCsvFileFromServer =
                await botManagerAPI.downloadTemplateFile(
                  latestVersion.id,
                  "templates/intent.csv"
                );
              intentFileBlob = new Blob([intentCsvFileFromServer.file_data], {
                type: "text/xml",
              });
              filesCount += 1;
              updateProgressCallback(
                `Preparing solution files ${filesCount}/3`
              );
            }
            if (serverFiles.indexOf("templates/entity.csv") > -1) {
              const entityCsvFileFromServer =
                await botManagerAPI.downloadTemplateFile(
                  latestVersion.id,
                  "templates/entity.csv"
                );
              entityFileBlob = new Blob([entityCsvFileFromServer.file_data], {
                type: "text/xml",
              });
              filesCount += 1;
              updateProgressCallback(
                `Preparing solution files ${filesCount}/3`
              );
            }
          }

          if (!intentFileBlob) {
            intentFileBlob = await (
              await fetch(new Request(INTENT_DEFAULT_FILE_URL))
            ).blob();
            filesCount += 1;
            updateProgressCallback(`Preparing solution files ${filesCount}/3`);
          }
          if (!entityFileBlob) {
            entityFileBlob = await (
              await fetch(new Request(ENTITY_DEFAULT_FILE_URL))
            ).blob();
            filesCount += 1;
            updateProgressCallback(`Preparing solution files ${filesCount}/3`);
          }

          updateProgressCallback(`Preparing solution files ${filesCount}/3`);
          const formData = new FormData();
          const nodsReadyToCsv = getNodesReadyForCsv(nodes);
          const csv = Papa.unparse(nodsReadyToCsv);
          const binaryData = [];
          binaryData.push(csv);
          const csvBlob = new Blob(binaryData, { type: "text/plain" });

          filesCount += 1;
          updateProgressCallback(`Preparing solution files ${filesCount}/3`);

          formData.append("templateFile", csvBlob, "bot.csv");
          formData.append("trainingIntentFile", intentFileBlob, "intent.csv");
          formData.append("trainingEntityFile", entityFileBlob, "entity.csv");

          let uploadToVersionId = null;
          if (hasDraft) {
            uploadToVersionId = latestVersion.id;
          } else {
            updateProgressCallback("Creating new draft version");
            await botManagerAPI.createNewBotVersion(
              `${customerId}.${botId}`,
              latestVersion ? latestVersion.version : null
            );
            const res = await dispatch(initBot(customerId, botId));
            uploadToVersionId = res.botData.latestVersion.id;
          }

          let errorCount = 0;
          nodes &&
            nodes.forEachNode((node) => {
              if (node.data.number >= 0 && node.errors) {
                errorCount += Object.keys(node.errors).length;
              }
            });

          const { data } = await botManagerAPI.uploadTemplate(
            uploadToVersionId,
            formData,
            5,
            (percent) => {
              updateProgressCallback(`Uploading solution ${percent}%`);
            }
          ); // 5 = NLU_ENABLED
          if (data.errors && Array.isArray(data.errors)) {
            // eslint-disable-next-line no-unused-vars
            const errorText = data.errors.map((error) => {
              const node = nodsReadyToCsv[error[0] - 2]; // -header -not zero index
              if (node) {
                return `* Node Name: ${node["Node Name"]} - ${error[1][0][0]} -  ${error[1][0][2]}`;
              }
              return "";
            });
          }

          dispatch({
            type: t.BOT_WAS_UPLOADED,
          });
          activityTracker.logEvent(
            activityTracker.eventTypeNames.SAVE_SOLUTION_CHANGES,
            {
              numberOfWarnings: errorCount,
              success: true,
              solutionName: `${state.designer.present.customerId}:${state.designer.present.botId}`,
            }
          );
          resolve();
        }
      } catch (e) {
        dispatch({
          type: t.BOT_WAS_NOT_UPLOADED,
        });
        reject(
          "Internet connectivity has been lost! \nYour changes have not been saved. \nTo save your changes please click “Save”"
        );
      }
    });
}

export function addGroup(geometry, childrenIds) {
  return (dispatch, getState) => {
    const state = getState();
    const { nodes, selectedGroup } = state.designer.present;
    const newNodeNumber = nodes.getNewNodeNumber();

    const newNameArr = [];

    const allChildren = childrenIds.map((id) => nodes.getNodeClone(id));

    //moving all children to the upper left corner while keeping the relative distance between them
    const minX = Math.min(...allChildren.map((node) => node.meta.x)) - 100;
    const minY = Math.min(...allChildren.map((node) => node.meta.y)) - 100;

    allChildren.forEach((node) => {
      node.meta.groupNumber = newNodeNumber;
      node.meta.x = node.meta.x - minX;
      node.meta.y = node.meta.y - minY;
      newNameArr.push(node.data.name);
      nodes.updateNode(node);
    });

    const node = {
      data: {
        number: newNodeNumber,
        type: "G",
        name: newNameArr.join(", "),
        next_nodes: [],
        what_next: [],
        variableCheckBox: false,
      },
      meta: {
        x: geometry.x,
        y: geometry.y,
        width: 200,
        height: 50,
        edges: [],
        isNew: true,
        isGroup: true,
        groupNumber: selectedGroup,
        childNodes: childrenIds,
      },
      touched: { ref: 0 },
    };

    //removing the nodes from prev group (using filter in case the node is there more than once)
    const selectedGroupNode = nodes.getNodeClone(selectedGroup);
    selectedGroupNode.meta.childNodes =
      selectedGroupNode.meta.childNodes.filter(
        (childNodeNumber) => !childrenIds.includes(childNodeNumber)
      );

    //add new group to its parent
    selectedGroupNode.meta.childNodes.push(newNodeNumber);
    nodes.updateNode(selectedGroupNode);
    nodes.insertNode(node);

    dispatch(updateNodesToStore(nodes, newNodeNumber, undefined));

    return node;
  };
}

export function addNodesToGroup(groupNumber, childrenIds) {
  return (dispatch, getState) => {
    const state = getState();
    const { nodes, selectedGroup } = state.designer.present;

    const allChildren = childrenIds.map((id) => nodes.getNodeClone(id));

    allChildren.forEach((node, index) => {
      node.meta.groupNumber = groupNumber;
      node.meta.x = 30;
      node.meta.y = 110 + (DEFAULT_NODE_HEIGHT + 30) * index;
      nodes.updateNode(node);
    });

    //adding the node to its parent childNodes
    const targetGroupNode = nodes.getNodeClone(groupNumber);
    if (targetGroupNode) {
      targetGroupNode.meta.childNodes =
        targetGroupNode.meta.childNodes.concat(childrenIds);
      nodes.updateNode(targetGroupNode);
    }

    //removing the nodes from prev group (using filter in case the node is there more than once)
    const selectedGroupNode = nodes.getNodeClone(selectedGroup);
    selectedGroupNode.meta.childNodes =
      selectedGroupNode.meta.childNodes.filter(
        (childNodeNumber) => !childrenIds.includes(childNodeNumber)
      );
    nodes.updateNode(selectedGroupNode);

    dispatch(updateNodesToStore(nodes, groupNumber, undefined));
  };
}

export function unGroup(groupNumber) {
  return (dispatch, getState) => {
    const { nodes, selectedGroup } = getState().designer.present;

    const deletedGroupNode = nodes.getNodeRead(groupNumber);

    if (deletedGroupNode && deletedGroupNode.data.type === "G") {
      const allChildren = deletedGroupNode.meta.childNodes.map((nodeNumber) =>
        nodes.getNodeClone(nodeNumber)
      );

      allChildren.forEach((node) => {
        if (node) {
          node.meta.groupNumber = selectedGroup;
          node.meta.x = node.meta.x + deletedGroupNode.meta.x;
          node.meta.y = node.meta.y + deletedGroupNode.meta.y;
          nodes.updateNode(node);
        }
      });

      //adding the node to its new parent childNodes
      const selectedGroupNode = nodes.getNodeClone(selectedGroup);
      if (selectedGroupNode) {
        selectedGroupNode.meta.childNodes = selectedGroupNode.meta.childNodes
          .concat(deletedGroupNode.meta.childNodes) //add new nodes
          .filter((nodeNumber) => nodeNumber !== groupNumber); // remove the group node
        nodes.updateNode(selectedGroupNode);
      }

      nodes.deleteNode(groupNumber);

      dispatch(updateNodesToStore(nodes, undefined, selectedGroup));
    }
  };
}

export function hideEdges(nodeNumber, status) {
  return (dispatch, getState) => {
    const { nodes } = getState().designer.present;

    const node = nodes.getNodeClone(nodeNumber);

    if (node) {
      node.meta.hideEdges = status;
      nodes.updateNode(node);
      dispatch(updateNodesToStore(nodes, undefined, undefined));
    }
  };
}

export function removeFromGroup(nodesNumbers) {
  return (dispatch, getState) => {
    if (nodesNumbers.length === 0) {
      return;
    }

    const { nodes } = getState().designer.present;
    const firstNode = nodes.getNodeRead(nodesNumbers[0]);
    const parentGroup = nodes.getNodeClone(firstNode.meta.groupNumber);

    nodesNumbers.forEach((nodeNumber) => {
      const node = nodes.getNodeClone(nodeNumber);

      if (node) {
        if (parentGroup) {
          node.meta.groupNumber = parentGroup.meta.groupNumber;
          node.meta.x = 30;
          node.meta.y = 110;
          nodes.updateNode(node);
        }
      }
    });

    //removing the node from prev group (using filter in case the node is there more than once)
    parentGroup.meta.childNodes = parentGroup.meta.childNodes.filter(
      (childNodeNumber) => !nodesNumbers.includes(childNodeNumber)
    );
    nodes.updateNode(parentGroup);

    //adding the node to its new parent childNodes
    const newParentGroup = nodes.getNodeClone(parentGroup.meta.groupNumber);
    newParentGroup.meta.childNodes =
      newParentGroup.meta.childNodes.concat(nodesNumbers);
    nodes.updateNode(newParentGroup);

    dispatch(updateNodesToStore(nodes, undefined, undefined));
  };
}

export function pasteNodes(
  selectedNode,
  selectedIndex,
  coordinate,
  clipType,
  setFocusOnNewNode = true
) {
  return (dispatch, getState) => {
    const state = getState();
    const { nodes, selectedGroup, clipboard, parentNodes } =
      state.designer.present;
    const newNodeNumber =
      clipType === "cut" ? selectedNode.data.number : nodes.getNewNodeNumber();
    const newNameArr = [];
    const childrenIds = selectedNode.meta.childNodes;

    if (selectedNode) {
      //validate prev node
      validateNode(selectedNode, null, null, nodes, false);
    }

    if (!coordinate) {
      coordinate = {
        x: selectedNode.meta.x,
        y: selectedNode.meta.y + selectedNode.meta.height + 100,
      };
    }

    if (
      clipType === "cut" &&
      selectedNode.data.type !== "G" &&
      parentNodes &&
      parentNodes.length
    ) {
      parentNodes.forEach((nodeNum) => {
        const node = nodes.getNodeRead(nodeNum);
        node.data.next_nodes.push(selectedNode.data.number);
        node.data.rich_asset_content &&
          node.data.rich_asset_content.options.forEach((o) => {
            if (o.next_node === null) o.next_node = selectedNode.data.number;
          });
        nodes.updateNode(node);
      });
    }

    if (selectedNode.data.type === "G") {
      const allChildren = childrenIds.map((child) => child);
      //moving all children to the upper left corner while keeping the relative distance between them
      const minX = Math.min(...allChildren.map((node) => node.meta.x)) - 100;
      const minY = Math.min(...allChildren.map((node) => node.meta.y)) - 100;
      allChildren.forEach((node, i) => {
        const nodeName =
          clipType === "cut" ? node.data.name : getNodeName(node, nodes);
        const nodeNumber =
          clipType === "cut" ? node.data.number : newNodeNumber + i + 1;
        node.meta.groupNumber = newNodeNumber;
        node.meta.x = node.meta.x - minX;
        node.meta.y = node.meta.y - minY;
        node.data.name = nodeName;
        node.data.number = nodeNumber;
        node.data.next_nodes = clipType === "cut" ? node.data.next_nodes : [];
        newNameArr.push(node.data.name);
        nodes.updateNode(node);
      });
    }

    const newNodeName =
      selectedNode.data.type === "A" || clipType === "cut"
        ? selectedNode.data.name
        : getNodeName(selectedNode, nodes);
    const node = {
      data: {
        ...selectedNode.data,
        number: newNodeNumber,
        name: newNodeName,
        copyParent:
          newNodeNumber !== selectedNode.data.number
            ? selectedNode.data.number
            : null,
        next_nodes: clipType === "cut" ? selectedNode.data.next_nodes : [],
      },
      meta: {
        ...selectedNode.meta,
        x: selectedIndex
          ? coordinate.x + (selectedNode.meta.x - clipboard[0].meta.x)
          : coordinate.x,
        y: selectedIndex
          ? coordinate.y + (selectedNode.meta.y - clipboard[0].meta.y)
          : coordinate.y,
        width: DEFAULT_NODE_WIDTH,
        height: DEFAULT_NODE_HEIGHT,
        groupNumber: selectedNode.meta.groupNumber || selectedGroup,
        childNodes:
          childrenIds &&
          childrenIds.length &&
          childrenIds.map((child) => child.data.number),
      },
      touched: { ref: 0 },
    };

    const selectedGroupNode = nodes.getNodeClone(selectedGroup);
    if (selectedNode.data.type === "G") {
      //removing the nodes from prev group (using filter in case the node is there more than once)
      selectedGroupNode.meta.childNodes =
        selectedGroupNode.meta.childNodes.filter(
          (childNodeNumber) => !childrenIds.includes(childNodeNumber)
        );
      //add new group to its parent
      selectedGroupNode.meta.childNodes.push(newNodeNumber);
    } else {
      //adding the node to its parent childNodes
      if (selectedGroupNode) {
        selectedGroupNode.meta.childNodes.push(newNodeNumber);
      }
    }
    nodes.updateNode(selectedGroupNode);
    nodes.insertNode(node);

    dispatch(
      updateNodesToStore(
        nodes,
        setFocusOnNewNode ? newNodeNumber : undefined,
        undefined
      )
    );
  };
}

function getNodeName(selectedNode, nodes) {
  const filterNodes = nodes.filterNodes(
    (node) => node.data.copyParent === selectedNode.data.number
  );
  const count = filterNodes.length ? filterNodes.length + 1 : "";
  return `${selectedNode.data.name} (Copy ${count})`;
}

export function openLeftSideDrawer() {
  return {
    type: t.OPEN_LEFT_SIDE_DRAWER,
  };
}

export function closeLeftSideDrawer() {
  return {
    type: t.CLOSE_LEFT_SIDE_DRAWER,
  };
}

export function openRightSideDrawer() {
  return {
    type: t.OPEN_RIGHT_SIDE_DRAWER,
  };
}

export function closeRightSideDrawer() {
  return {
    type: t.CLOSE_RIGHT_SIDE_DRAWER,
  };
}
