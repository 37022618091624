/* eslint-disable no-useless-escape */
export default `
# -*- coding: utf-8 -*-
r'''
    ______  ______  _____________________  _________    __  ___
   / __ \ \/ / __ \/ ____/ ___/_  __/ __ \/ ____/   |  /  |/  /
  / /_/ /\  / /_/ / __/  \__ \ / / / /_/ / __/ / /| | / /|_/ /
 / ____/ / / ____/ /___ ___/ // / / _, _/ /___/ ___ |/ /  / /
/_/     /_/_/   /_____//____//_/ /_/ |_/_____/_/  |_/_/  /_/
action node script

'''
import requests
import json
import time


N8N_BASE_URL = "https://n8n.coywolf.studio/rest"
WORKFLOW_BASE_URL = "https://n8n.coywolf.studio/rest/workflows"


def inject_global_vars_into_workflow(workflow_data, global_vars):
    if "data" in workflow_data and "nodes" in workflow_data["data"]:
        for node in workflow_data["data"]["nodes"]:
            node["parameters"] = {param: global_vars.get(param, node["parameters"][param]) \
                for param in node["parameters"]}

    workflow_data["data"].pop("createdAt")
    workflow_data["data"].pop("updatedAt")
    workflow_data["workflowData"] = workflow_data["data"]
    workflow_data.pop("data")

    return workflow_data


def get_workflow_object(workflow_name):
    """
    Gets workflow object by workflow name
    """
    url = f"{WORKFLOW_BASE_URL}/name/{workflow_name}"
    response = requests.get(url)
    workflow_obj = response.json()

    return workflow_obj


def get_execution_id(workflow_data):
    """
    Executes workflow by passing workflow object
    and returns the status and execution id
    """
    url = f"{WORKFLOW_BASE_URL}/run"
    response = requests.post(url, json=workflow_data)
    if response.status_code != 200:
        return False, response.text

    execution_id = response.json()["data"]["executionId"]
    return True, execution_id


def fine_tune_payload(payload):
    if isinstance(payload,dict):
        for data in payload:
            if isinstance(payload[data],str):
                payload[data] = result_data[int(payload[data])]


def get_execution_result(execution_id, workflow_data, log):
    url = f"{N8N_BASE_URL}/executions/{execution_id}"
    time.sleep(5)
    response = requests.get(url)

    result_data = json.loads(response.json()["data"]["data"])
    log(f"Result data from executions/{execution_id}: {result_data}")
    return_payload = {}
    for elements in result_data:
        if "json" in elements:
            for element in elements:
                if isinstance(elements[element], str):
                    return_payload.update(result_data[int(elements[element])])
                else:
                    return_payload[element] = element[elements]
    fine_tune_payload(return_payload)

    for data in return_payload:
        fine_tune_payload(return_payload[data])

    return return_payload


class ExecuteWorkflow:
    def execute(self, log, payload=None, bot_or_context=None):
        return_payload = {}
        try:
            workflow_name = payload["jsonFileName"]
            workflow_obj = get_workflow_object(workflow_name)

            # extract global vars and create workflow_data
            workflow_data = inject_global_vars_into_workflow(workflow_obj, payload)

            # pass updated workflow object and execute workflow
            workflow_data["mode"] = "cli"
            success, execution_id = get_execution_id(workflow_data)

            # get execution result and return payload
            if success:
                execution_result = get_execution_result(execution_id, workflow_data, log)
                return_payload["success"] = "true"
                return_payload["WORKFLOW_OUTPUT"] = execution_result
                log(f"Successful execution of workflow: {workflow_name}")
                log(f"Execution result: {execution_result}")
            else:
                log(f"Error during workflow {workflow_name} execution: {execution_id}")
                return_payload["success"] = "false"
            return return_payload


        except Exception as err:
            log(f"Workflow {workflow_name} Exception error: {err}")
            return_payload["success"] = "false"
            return return_payload
`;
