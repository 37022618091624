import {
  RICH_ASSET_TYPE_BUTTON,
  RICH_ASSET_TYPE_CAROUSEL,
  RICH_ASSET_TYPE_FILE_UPLOAD,
  RICH_ASSET_TYPE_IMAGEBUTTON,
  RICH_ASSET_TYPE_SELECTION,
  RICH_ASSET_TYPE_LISTPICKER,
} from "./defs";

function extractHostname(url) {
  let hostname = "";
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url) {
    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    // find & remove port number
    hostname = hostname.split(":")[0];
    // find & remove "?"
    hostname = hostname.split("?")[0];
  }
  return hostname;
}

// To address those who want the "root domain," use this function:
export function extractRootDomain(url) {
  let domain = extractHostname(url);

  const splitArr = domain.split(".");

  const arrLen = splitArr.length;

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`;
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (
      splitArr[arrLen - 2].length === 2 &&
      splitArr[arrLen - 1].length === 2
    ) {
      // this is using a ccTLD
      domain = `${splitArr[arrLen - 3]}.${domain}`;
    }
  }
  return domain;
}

export function getIsTypeSupportNode2NodeConnection(rich_asset_type) {
  return (
    !rich_asset_type ||
    (rich_asset_type !== RICH_ASSET_TYPE_BUTTON &&
      rich_asset_type !== RICH_ASSET_TYPE_IMAGEBUTTON &&
      rich_asset_type !== RICH_ASSET_TYPE_SELECTION &&
      rich_asset_type !== RICH_ASSET_TYPE_LISTPICKER &&
      rich_asset_type !== RICH_ASSET_TYPE_CAROUSEL &&
      rich_asset_type !== RICH_ASSET_TYPE_FILE_UPLOAD)
  );
}

export function uniq(a) {
  return Array.from(new Set(a));
}

export function getEdgeLineV2(sourceMeta, targetMeta) {
  function getIntersection(dx, dy, cx, cy, w, h) {
    if (Math.abs(dy / dx) < h / w) {
      // Hit vertical edge of box1
      return [cx + (dx > 0 ? w : -w), cy + (dy * w) / Math.abs(dx), 0];
    }
    // Hit horizontal edge of box1
    return [cx + (dx * h) / Math.abs(dy), cy + (dy > 0 ? h : -h), 1];
  }

  let polylineStr = "";
  let arrow = "";

  if (targetMeta) {
    const cx1 = sourceMeta.x + sourceMeta.width / 2;
    const cy1 = sourceMeta.y + sourceMeta.height / 2;
    const w1 = sourceMeta.width / 2;
    const h1 = sourceMeta.height / 2;

    const cx2 = targetMeta.x + targetMeta.width / 2;
    const cy2 = targetMeta.y + targetMeta.height / 2;
    const w2 = targetMeta.width / 2;
    const h2 = targetMeta.height / 2;

    const dx = cx2 - cx1;
    const dy = cy2 - cy1;

    if (cx1 !== cx2 || cy1 !== cy2) {
      const p1 = getIntersection(dx, dy, cx1, cy1, w1, h1);
      const p2 = getIntersection(-dx, -dy, cx2, cy2, w2, h2);

      if (p2[2]) {
        if (p1[1] < p2[1]) {
          polylineStr = `${p1[0]},${p1[1]} ${p2[0]},${p2[1] - 20} ${p2[0]},${
            p2[1] - 9
          }`;
          const x = p2[0];
          const y = p2[1] - 9;
          arrow = `M ${x} ${y + 5.25} L ${x - 3.5} ${y - 1.75} L ${x} ${y} L ${
            x + 3.5
          } ${y - 1.75} Z`;
        } else {
          polylineStr = `${p1[0]},${p1[1]} ${p2[0]},${p2[1] + 20} ${p2[0]},${
            p2[1] + 9
          }`;
          const x = p2[0];
          const y = p2[1] + 9;
          arrow = `M ${x} ${y - 5.25} L ${x - 3.5} ${y + 1.75} L ${x} ${y} L ${
            x + 3.5
          } ${y + 1.75} Z`;
        }
      } else if (p1[0] < p2[0]) {
        polylineStr = `${p1[0]},${p1[1]} ${p2[0] - 20},${p2[1]} ${p2[0] - 9},${
          p2[1]
        }`;
        const x = p2[0] - 9;
        const y = p2[1];
        arrow = `M ${x + 5.25} ${y} L ${x - 1.75} ${y - 3.5} L ${x} ${y} L ${
          x - 1.75
        } ${y + 3.5} Z`;
      } else {
        polylineStr = `${p1[0]},${p1[1]} ${p2[0] + 20},${p2[1]} ${p2[0] + 9},${
          p2[1]
        }`;
        const x = p2[0] + 9;
        const y = p2[1];
        arrow = `M ${x - 5.25} ${y} L ${x + 1.75} ${y - 3.5} L ${x} ${y} L ${
          x + 1.75
        } ${y + 3.5} Z`;
      }
    }
  }

  return { polyline: polylineStr, arrow };
}

/**
 * @return {string}
 */
export function GetFilenameFromUrl(url) {
  if (url) {
    const matches = url.match(/([^/?#]+)[^/]*$/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
  }
  return "";
}

export function scrollTo(element, top, left) {
  if (element.scrollTo) {
    element.scrollTo({
      top,
      left,
      //   behavior: 'smooth'
    });
  } else {
    element.scrollTop = top;
    element.scrollLeft = left;
  }
}
