import config from "./config";
import amplitude from "amplitude-js";
import mixpanel from "mixpanel-browser";

function init() {
  if (!config.amplitudeApiKey) {
    console.error("PS_AMPLITUDE_API_KEY was not defined");
  }
  amplitude.getInstance().init(config.amplitudeApiKey, undefined, {
    trackingOptions: {
      city: false,
      dma: false,
      ip_address: false,
    },
  });
  if (!config.mixpanelApiKey) {
    console.error("PS_MIXPANEL_API_KEY was not defined");
  }
  mixpanel.init(config.mixpanelApiKey);
}

/**
 * Log an event with eventType and eventProperties
 * @public
 * @param {string} eventType - name of event
 * @param {object} eventProperties - (optional) an object with string keys and values for the event properties.
 * @example logEvent('Clicked Homepage Button', {'finished_flow': false, 'clicks': 15});
 */
function logEvent(eventType, eventProperties = undefined) {
  const Appcues = window.Appcues;
  amplitude.getInstance().logEvent(eventType, eventProperties, (code) => {
    if (code !== 200) {
      console.error(`Error in sending event:  ${eventType}, code: ${code}`);
    }
  });
  mixpanel.track(eventType, eventProperties, {}, "", false, (code) => {
    if (code !== 200) {
      console.error(`Error in sending event:  ${eventType}, code: ${code}`);
    }
  });
  Appcues &&
    Appcues.track(eventType, eventProperties, (code) => {
      if (code !== 200) {
        console.error(`Error in sending event:  ${eventType}, code: ${code}`);
      }
    });
}

const eventTypeNames = {
  UPLOAD_MEDIA_FILE: "Upload Media File",
  UPLOAD_SCRIPT: "Upload Action Node",
  UPLOAD_CONFIG: "Upload Config",
  TRAIN: "Train NLU",
  PUBLISH: "Publish Solution",
  CREATE_NEW_SOLUTION: "Create New Solution",
  ACCESSED_SOLUTION_MANAGEMENT: "Open Studio: Manage",
  ACCESSED_DESIGN_STUDIO: "Open Studio: Design",
  UPLOAD_TEMPLATE: "Upload Template",
  VIEW_ACTION_NODE_LIBRARY: "View Action Node Library",
  CLICK_PREVIEW: "Preview Solution",
  OPEN_ACTION_NODE_EDITOR: "Open Action Node Editor",
  COPY_JWT: "Copy JWT",
  OPEN_CSS_EDITOR: "Open CSS Editor",
  CREATE_CHANNEL: "Create Channel",
  OPEN_PYPE_IN_NEW_TAB: "Open Pype in New Tab",
};

export default {
  init,
  logEvent,
  eventTypeNames,
  mixpanel,
};
