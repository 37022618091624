import React from "react";
import { PypestreamLoader } from "ui-elements";
import PropTypes from "prop-types";
import clsx from "clsx";

import "../Styles/Loader.scss";

const Loader = ({ center }) => (
  <div className={clsx("pypestream-manager-loader", { center: center })}>
    <PypestreamLoader />
  </div>
);

Loader.propTypes = {
  center: PropTypes.bool,
};

Loader.defaultProps = {
  center: true,
};

export default Loader;
