import { validatorHTTPS } from "Designer/lib/validators";
import botManagerAPI from "../../../../bot-manager-api";

export function decodeRichAssetContentWebView(data, meta) {
  let ret = null;
  const richAssetContent = data.rich_asset_content;
  if (richAssetContent) {
    const parts = richAssetContent.split("~");
    if (parts.length === 2) {
      ret = {
        name: parts[0] || data.message,
        url: parts[1],
        ogs: meta.rich_content ? meta.rich_content.ogs : null,
      };
    }
  }
  return ret;
}

export function encodeRichAssetContentWebView(data) {
  const richAssetContent = data.rich_asset_content;
  return `${data.message}~${richAssetContent.url}`;
}

export function validateRichAssetContentWebView(
  richAssetContent,
  touched_rich_asset_content,
  validateOnlyTouched
) {
  const errors = {};
  let foundError = false;
  if (
    richAssetContent &&
    (!validateOnlyTouched ||
      (validateOnlyTouched &&
        touched_rich_asset_content &&
        touched_rich_asset_content.url))
  ) {
    const urlError = validatorHTTPS(richAssetContent.url);
    if (urlError) {
      errors.url = urlError;
      foundError = true;
    }
  }
  return foundError ? errors : undefined;
}

export async function getGraph(node, updateRichContent) {
  const url = node.data.rich_asset_content.url;
  const rich_asset_content = node.data.rich_asset_content;

  if (url) {
    try {
      const res = await botManagerAPI.getSiteMetadata(url);
      const ogs = {
        ...res,
        success: true,
      };
      const newRichContent = Object.assign({}, rich_asset_content, { ogs });
      updateRichContent(newRichContent);
    } catch (e) {
      console.error(e);
      const newRichContent = Object.assign({}, rich_asset_content, {
        ogs: { success: false },
      });
      updateRichContent(newRichContent);
    }
  } else {
    const newRichContent = { url };
    updateRichContent(newRichContent);
  }
}
