export const PREDEFINED_NODE_IDS = [9999, 99990, 1800, 666, 999];

export const DEFAULT_SOLUTION_JSON_DATA = [
  {
    number: 1,
    type: "D",
    name: "Welcome",
    nluRouting: { intent: null, type: null, entity: null },
    nodeContent: {
      nextNode: null,
      behaviors: ["disable_input"],
      messages: [
        {
          messageType: "text",
          message: "Greetings! This is the first node in your microapp.",
        },
      ],
    },
    metadata: {
      usecase: "dialog",
    },
  },
  {
    number: 666,
    type: "D",
    name: "End Conversation",
    nluRouting: { intent: "end_chat", type: null, entity: null },
    nodeContent: {
      nextNode: null,
      messages: [
        {
          messageType: "text",
          message: "Goodbye.",
        },
      ],
    },
    metadata: {
      usecase: "system",
    },
  },
  {
    number: 999,
    type: "D",
    name: "Escalate to Agent",
    nluRouting: { intent: "transfer_to_agent", type: null, entity: null },
    nodeContent: {
      nextNode: null,
      messages: [
        {
          messageType: "text",
          message: "Transferring you to an agent.",
        },
      ],
      behaviors: ["xfer_to_agent"],
    },
    metadata: {
      usecase: "system",
    },
  },
  {
    number: 99990,
    type: "D",
    name: "Error",
    nluRouting: { intent: null, type: null, entity: null },
    nodeContent: {
      nextNode: null,
      messages: [
        {
          messageType: "text",
          message: "Sorry, I encountered a technical error.",
        },
      ],
      richAssetType: "quick_reply",
      richAssetContent: {
        type: "static",
        options: [
          {
            label: "Start over",
            dest: 1,
          },
        ],
      },
      behaviors: ["disable_input"],
      answerRequired: true,
    },
    metadata: {
      usecase: "system",
    },
  },
  {
    number: 1800,
    type: "D",
    name: "Out of Scope",
    nluRouting: { intent: "out_of_scope", type: null, entity: null },
    nodeContent: {
      nextNode: null,
      messages: [
        {
          messageType: "text",
          message: "Sorry, I didn't understand your message.",
        },
      ],
      richAssetType: "quick_reply",
      richAssetContent: {
        type: "static",
        options: [
          {
            label: "Start over",
            dest: 1,
          },
        ],
      },
      behaviors: ["disable_input"],
      answerRequired: true,
    },
    metadata: {
      usecase: "system",
    },
  },
];
