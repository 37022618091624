/* eslint-disable no-case-declarations */
import isEmpty from "lodash/isEmpty";
import { NODE_SETTING } from "../enum";

const NodeType = NODE_SETTING.TYPE;
const BubbleTypes = NODE_SETTING.BUBBLE_TYPES;
const EmbedTypes = NODE_SETTING.EMBED_TYPES;

const getNextNode = (node) => {
  let linkto = "";

  if (!isEmpty(node.embeds)) {
    let textfield, embed;
    switch (node.embeds.type) {
      case "None":
        textfield = node.textfield[node.embeds.type];
        if (textfield.data) {
          linkto = textfield.data.link.number;
        }
        break;
      case "Date Picker":
      case "Time Picker":
        embed = node.embeds[node.embeds.type];
        if (embed) {
          linkto = embed.link.number;
        }
        break;
      case "Buttons":
      case "List Picker":
      case "Carousel":
        embed = node.embeds[node.embeds.type];

        if (embed.type === "standard") {
          const data = embed[embed.type];
          linkto = (data || []).map((item) => item.link.number).join(",");
        } else if (embed.type === "dynamic") {
          const data = embed[embed.type];
          linkto = (data.sourceNode || {}).number;
        } else {
          const data = embed[embed.type];
          linkto = (data || []).map((item) => item.link.number).join(",");
        }

        break;
      default:
        break;
    }
  }

  return linkto;
};

const getRowsForMessageBubbles = (node) => {
  return (node.messages || []).map((bubble, index) => {
    let res = {};
    const { data } = bubble || {};

    switch (bubble.type) {
      case BubbleTypes.TEXT:
        res = {
          id: data.id,
          type: "D",
          title: "",
          linkto:
            node.messages.length > index + 1
              ? node.messages[index + 1].data.id
              : getNextNode(node),
          message: data.text,
          richAssetType: "",
          answerRequired: 0,
          behaviors: "disable_input",
        };
        break;
      case BubbleTypes.MEDIA:
        res = {
          id: data.id,
          type: "D",
          title: "",
          linkto:
            node.messages.length > index + 1
              ? node.messages[index + 1].data.id
              : getNextNode(node),
          message: "",
          richAssetType: "image",
          richAssetContent: JSON.stringify({
            type: "static",
            url: data.image,
            alt_text: data.text,
          }).replaceAll(/"/g, '""'),
          answerRequired: 0,
          behaviors: "disable_input",
        };
        break;
      case BubbleTypes.FILE:
        const getCorrectFileURLData = (text) => {
          if (text) {
            const files = text.split(",");

            if (files.length === 1) {
              return [text, text].join(",");
            } else {
              return files.slice(0, 2).join(",");
            }
          }
          return "";
        };

        res = {
          id: data.id,
          type: "D",
          title: "",
          linkto:
            node.messages.length > index + 1
              ? node.messages[index + 1].data.id
              : getNextNode(node),
          message: "",
          richAssetType: "document",
          richAssetContent: getCorrectFileURLData(data.text),
          answerRequired: 0,
          behaviors: "disable_input",
        };
        break;
      default:
        break;
    }
    return res;
  });
};

const getTextFieldColumns = (textfield) => {
  let res = {};

  if (!textfield) {
    return res;
  }

  let setting = textfield[textfield.type] || {};
  if (!isEmpty(textfield)) {
    switch (textfield.type) {
      case EmbedTypes.BUTTONS:
      case EmbedTypes.CAROUSEL:
      case EmbedTypes.LIST_PICKER:
        res = {
          behaviors: setting.enabled ? "" : "disable_input",
          ...(setting.enabled
            ? {
                NLUDisabled: setting.data.checkedNavigate ? 0 : 1,
              }
            : {}),
        };
        break;
      case EmbedTypes.NONE:
        res = {
          linkto: setting.data.link.number,
          behaviors: setting.enabled ? "" : "disable_input",
          ...(setting.enabled
            ? {
                NLUDisabled: setting.data.isCollect === "navigate" ? 0 : 1,
                variable: setting.data.userVariables
                  .map((item) => item.value)
                  .join(","),
                richAssetType: setting.data.checkedInfo ? "secure" : "",
                richAssetContent: JSON.stringify({}).replaceAll(/"/g, '""'),
              }
            : {}),
        };
        break;
      default:
        break;
    }
  } else {
    res = {
      behaviors: "disable_input",
    };
  }

  return res;
};

const getEmbedColumns = (embeds) => {
  let res = {};

  let embed;
  let settings;
  if (!isEmpty(embeds)) {
    switch (embeds.type) {
      case EmbedTypes.BUTTONS:
        embed = embeds[embeds.type];
        settings = (embed.settings || {})[embed.type] || {};
        const embedData = embed[embed.type];

        switch (embed.type) {
          case "text":
            res = {
              richAssetType: "quick_reply",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "static",
                options: (embedData || []).map((option) => ({
                  label: option.label || "",
                  dest: option.link.number || 0,
                })),
              }).replaceAll(/"/g, '""'),
              variable: settings.saveUserSelection
                ? (settings.userVariables || [])
                    .map((item) => item.value)
                    .join(",")
                : null,
            };
            break;
          case "images":
            res = {
              richAssetType: "imagebutton",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "static",
                options: (embedData || []).map((option) => ({
                  label: option.label || "",
                  dest: option.link.number || 0,
                  image: option.image,
                })),
              }).replaceAll(/"/g, '""'),
              variable: settings.saveUserSelection
                ? (settings.userVariables || [])
                    .map((item) => item.value)
                    .join(",")
                : null,
            };
            break;
          case "dynamic":
            res = {
              richAssetType: "quick_reply",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "dynamic",
                source_node: (embedData.sourceNode || {}).number,
                source_var: embedData.sourceVar || "",
              }).replaceAll(/"/g, '""'),
            };
            break;
          default:
            break;
        }
        break;
      case EmbedTypes.CAROUSEL:
        embed = embeds[embeds.type];

        switch (embed.type) {
          case "standard":
            const settings = embed.settings.standard;
            res = {
              richAssetType: "carousel",
              answerRequired: 1,
              richAssetContent: JSON.stringify(
                (embed.standard || []).map((item) => ({
                  title: (item.label || {}).value || "",
                  image: item.image,
                  subtitle: settings.hasSubtitle
                    ? (item.subTitle || {}).value || ""
                    : "",
                  opt_text: settings.hasDescription
                    ? (item.description || {}).value || ""
                    : "",
                  label: settings.hasSelectLabel
                    ? (item.buttonLabel || {}).value || ""
                    : "",
                  dest: `${(item.link || {}).number || 0}`,
                  url: "",
                  // additional_cta: [], // Disabling since it's not supported yet.
                }))
              ).replaceAll(/"/g, '""'),
            };
            break;
          case "dynamic":
            const data = embed.dynamic || {};
            res = {
              richAssetType: "carousel",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "dynamic",
                source_node: (data.sourceNode || {}).number,
                source_var: data.sourceVar || "",
              }).replaceAll(/"/g, '""'),
            };
            break;
          default:
        }
        break;
      case EmbedTypes.LIST_PICKER:
        embed = embeds[embeds.type];

        switch (embed.type) {
          case "text":
            settings = (embed.settings || {}).text || {};
            res = {
              richAssetType: "listpicker",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "static",
                options: (embed.text || []).map((item) => ({
                  label: item.label || "",
                  dest: `${
                    settings.allowMultiSelect
                      ? (settings.linkTo || {}).number || 0
                      : (item.link || {}).number || 0
                  }`,
                })),
              }).replaceAll(/"/g, '""'),
              variable: settings.saveUserSelection
                ? (settings.userVariables || [])
                    .map((item) => item.value)
                    .join(",")
                : null,
              behaviors: settings.allowMultiSelect ? "multiple_choice" : null,
            };
            break;
          case "images":
            settings = (embed.settings || {}).images || {};
            res = {
              richAssetType: "listpicker",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "static",
                options: (embed.images || []).map((item) => ({
                  label: item.label || "",
                  dest: `${(item.link || {}).number || 0}`,
                  image: item.image,
                  description: settings.addDescription
                    ? item.description
                    : null,
                })),
              }).replaceAll(/"/g, '""'),
              variable: settings.saveUserSelection
                ? (settings.userVariables || [])
                    .map((item) => item.value)
                    .join(",")
                : null,
            };
            break;
          case "dynamic":
            const data = embed.dynamic || {};
            res = {
              richAssetType: "listpicker",
              answerRequired: 1,
              richAssetContent: JSON.stringify({
                type: "dynamic",
                source_node: (data.sourceNode || {}).number,
                source_var: data.sourceVar || "",
              }).replaceAll(/"/g, '""'),
            };
            break;
          default:
        }
        break;
      case EmbedTypes.NONE:
        res = {};
        break;
      default:
    }
  }

  return res;
};

const getNLUColumns = (NLU) => ({
  intent: NLU.enabled ? NLU.intent : null,
  entity: NLU.enabled ? NLU.entity : null,
  entityType: NLU.enabled ? NLU.entityType : null,
  NLUDisabled: NLU.enabled ? 0 : 1,
});

const isEmbedSelected = (node) => {
  let res = false;

  if (node.embeds && node.embeds.type && node.embeds[node.embeds.type]) {
    res = !isEmpty(node.embeds[node.embeds.type]);
  }

  return res;
};

const getRowsForEachNode = (node) => {
  let result = [];

  if (node.number !== 0) {
    if (node.type === NodeType.DECISION) {
      result = [
        {
          id: node.number,
          type: "D",
          title: node.title,
          linkto: isEmbedSelected(node)
            ? null
            : isEmpty(node.messages)
            ? getNextNode(node)
            : node.messages[0].data.id,
          ...getNLUColumns(node.NLU || {}),
          ...getEmbedColumns(node.embeds),
          ...getTextFieldColumns(node.textfield),
        },
        ...getRowsForMessageBubbles(node),
      ];
    } else if (node.type === NodeType.ACTION) {
      const actionData = node.action || {};
      result = [
        {
          id: node.number,
          type: "A",
          title: node.title,
          command: node.title,
          description: actionData.description,
          nodeInput: (actionData.inputs.global_variable || [])
            .map(
              (item) => `${item.value.toUpperCase()}:${node.parent.slice(1)}`
            )
            .join(","),
          variable: (actionData.inputs.global_variable || [])
            .map((item) => item.value.toUpperCase())
            .join(","),
          output: (actionData.outputs_variables || [])
            .map((item) => item.value.toUpperCase())
            .join(","),
          parameterInput: actionData.inputs.parameters
            ? actionData.inputs.parameters.replaceAll(/"/g, '""')
            : "{}",
          decisionVariable:
            actionData.conditions[0].condition.variable.toUpperCase(),
          whatNext: actionData.conditions
            .map(
              (cond) => `${cond.condition.value}~${cond.condition.link.number}`
            )
            .join("|"),
          ...getNLUColumns(node.NLU || {}),
        },
      ];
    }
  }

  return result;
};

const solutionDataLoop = (node) => {
  let result = getRowsForEachNode(node);

  if (!node.children || node.children.length === 0) {
    return result;
  }

  node.children.forEach((child) => {
    result = [...result, ...solutionDataLoop(child)];
  });

  return [...result];
};

const changeToArrayLoop = (node) => {
  let result = [
    {
      ...node,
    },
  ];

  if (!node || !node.children || node.children.length === 0) {
    return result;
  }

  node.children.forEach((child) => {
    result = [...result, ...changeToArrayLoop(child)];
  });

  return [...result];
};

const parseSolutionDataToCSVData = (data) => {
  return solutionDataLoop(data);
};

const convertSolutionDataToArray = (data) => {
  return changeToArrayLoop(data);
};

export { parseSolutionDataToCSVData, convertSolutionDataToArray };
