const config = {
  botManagerAPIurl: window.config.PS_BOT_MANAGER_FRONTEND_BOT_MANAGER_API_URL,
  s3bucketUrl: window.config.PS_BOT_MANAGER_FRONTEND_S3_BUCKET_URL,
  homepage: window.config.PS_BOT_MANAGER_FRONTEND_HOMEPAGE || "",
  OktaAuthConfig: {
    url: window.config.PS_BOT_MANAGER_FRONTEND_OKTA_URL,
    issuer: window.config.PS_BOT_MANAGER_FRONTEND_OKTA_ISSUER,
    redirectUri: window.location.origin,
    clientId: window.config.PS_BOT_MANAGER_FRONTEND_OKTA_CLIENT_ID,
    scope: ["openid", "email", "profile", "pypestream_access"],
    tokenManager: {
      storage: "sessionStorage",
    },
  },
  supportedTrainingLanguages: ["english", "spanish"],
  supportedBotTypes: ["main", "survey"],
  amplitudeApiKey: window.config.PS_AMPLITUDE_API_KEY,
  webserviceEnvConfig: window.config.PS_ENV_CONFIGS,
  launchDarklySdk: window.config.LAUNCHDARKLY_SDK,
  launchDarklyClientId: window.config.LAUNCHDARKLY_CLIENT_ID,
  mixpanelApiKey: window.config.MIXPANEL_PROJECT_ID,
  hotjarId: window.config.PS_ENV_CONFIGS["live"]?.HOTJAR_ID,
  hotjarLocalId: window.config.PS_ENV_CONFIGS["live"]?.HOTJAR_LOCAL_ID,
};

export default config;
